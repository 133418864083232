import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { setBatchSettings, setBatchesWorking, setBatches, fetchBatchesRequest, setMaxBatchesPage, fetchBatchSettingsRequest } from "./actions";
import { FETCH_BATCH_WITH_ORDER_ID_REQUEST, FETCH_BATCHES_REQUEST, GENERATE_BATCH_REQUEST, UPDATE_USER_FOR_BATCH_REQUEST, FETCH_BATCH_SETTINGS_REQUEST, UPDATE_BATCH_SETTINGS_REQUEST } from "./actionTypes";
import { UpdateUserForbatch, FetchBatchesRequest, FetchBatchWithOrderIDRequest, FetchBatchSettingsRequest, UpdateBatchSettingsRequest } from "./types";
import { getBatchesCurrentPage, getBatches, getUsers } from '../config/selectors';
import { setMessage } from '../message/actions';

type OrdersPerBatch = {
    id : number,
    orders_per_batch : number
}

type UpdateUserForBatchSatus = {
    status : string
}

const getBatchesRequest = (page : number) => axios.get<BatchResponse>(window.apiUrl + '/getBatches?page='+page).then(response => { return response.data})
const getBatchWithOrderIDRequest = (orderID : number) => axios.get<BatchResponse>(window.apiUrl + '/getBatches?page=1&orderId='+orderID).then(response => { return response.data})

const generateBatchRequest = () => axios.get<GenerateBatchResponse>(window.apiUrl +  '/forceGenerateBatch').then(response => { return response.data});
const updateUserForBatchRequest = (batchID : number, userID : number) => axios.get<UpdateUserForBatchSatus>(window.apiUrl +  '/setUserForBatch/'+batchID +'/' + userID).then(response => { return response.data});
const getOrdersPerBatchRequest = () => axios.get<OrdersPerBatch>(window.apiUrl + '/getOrdersPerBatch').then(response => { return response.data})
const updateBatchSettingsRequest = ( amount : number ) => axios.post<number>(window.apiUrl +  '/updateBatchSettings',  {amount: amount}).then(response => { return response.status });


function* fetchBatches(action : FetchBatchesRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setBatchesWorking(true));
        const response: BatchResponse = yield call(getBatchesRequest, action.payload);

        if ( response.batches ) {
            yield put(
                setBatches({
                    batches: response.batches.data,
                })
            )
            yield put(setMaxBatchesPage(response.batches.last_page));
        }

        yield put(setBatchesWorking(false));

    }catch(e : any) {
        yield put(setBatchesWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batches: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}



function* fetchBatchWithOrderID(action : FetchBatchWithOrderIDRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setBatchesWorking(true));
        const response: BatchResponse = yield call(getBatchWithOrderIDRequest, action.payload);

        if ( response.batches ) {
            yield put(
                setBatches({
                    batches: response.batches.data,
                })
            )
            yield put(setMaxBatchesPage(response.batches.last_page));
        }

        yield put(setBatchesWorking(false));

    }catch(e : any) {
        yield put(setBatchesWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batches with Order ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* fetchBatchSettings(action : FetchBatchSettingsRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        const response: OrdersPerBatch = yield call(getOrdersPerBatchRequest);
        const batchSettings = {
            orders_per_batch : response.orders_per_batch
        }

        yield put(
            setBatchSettings(batchSettings)
        )


    }catch(e : any) {
        yield put(setBatchesWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batch settings: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


///api/forceGenerateBatch
function* generateBatch() /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        //const response: GenerateBatchResponse = yield call(generateBatchRequest);
        yield call(generateBatchRequest);
        const page : number = yield select(getBatchesCurrentPage);
        yield put(fetchBatchesRequest(page));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batches: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateUserForBatch( action : UpdateUserForbatch) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let batches : Batch[] = yield select(getBatches);

        const batchIndex : number  = batches.findIndex((item : Batch) => item.id === action.payload.batchID);
        const users : User[] = yield select(getUsers);
        const user : User | undefined = users.find((user : User) => user.id === action.payload.userID);

        if ( batchIndex > -1 && user ) {
            batches[batchIndex] = {
                ...batches[batchIndex],
                user : user,
                user_id : action.payload.userID
            }
            yield put(
                setBatches({
                    batches: batches
                })
            )
        }
        const status: UpdateUserForBatchSatus = yield call(updateUserForBatchRequest, action.payload.batchID, action.payload.userID);

        if ( status.status !== 'OK' ) {
            const page : number = yield select(getBatchesCurrentPage);
            yield put(fetchBatchesRequest(page));
            yield put(
                setMessage({
                    notification : {
                        message : 'Updating batch user failed',
                        type : 'error'
                    }
                })
            );
        }

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while updating batch user: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateBatchSettings (action : UpdateBatchSettingsRequest) {
    try {
        const result : number = yield call(updateBatchSettingsRequest, action.payload.settings.orders_per_batch);
        if ( result === 200 ) {
            yield put(fetchBatchSettingsRequest());
            yield put(
                setMessage({
                    notification : {
                        message : 'Batch settings updated successfully.',
                        type : 'success'
                    }
                })
            );
        } else {
            yield put(
                setMessage({
                    notification : {
                        message : 'Got wrong status while updating batch settings: ' + result,
                        type : 'success'
                    }
                })
            );
        }
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while updating batch settings: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* batchSaga() {
  yield all([
      takeLatest(FETCH_BATCHES_REQUEST, fetchBatches),
      takeLatest(GENERATE_BATCH_REQUEST, generateBatch),
      takeLatest(UPDATE_USER_FOR_BATCH_REQUEST, updateUserForBatch),
      takeLatest(FETCH_BATCH_SETTINGS_REQUEST, fetchBatchSettings),
      takeLatest(UPDATE_BATCH_SETTINGS_REQUEST, updateBatchSettings),
      takeLatest(FETCH_BATCH_WITH_ORDER_ID_REQUEST, fetchBatchWithOrderID)
      //fork(startStopIndexStatusUpdates)
  ]);
}

export default batchSaga;
