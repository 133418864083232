import axios from '../../AxiosInstance/axiosWrapper';
import { push } from 'react-router-redux';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setUser, setUserRoles, setAllUsers, setToken, fetchAllUsers, authenticate } from "./actions";
import { RESET_PASSWORD, REQUEST_PASSWORD_RESET, DELETE_USER, SET_USER, CREATE_USER_REQUEST, UPDATE_USER_REQUEST, GET_ALL_USERS_REQUEST, AUTHENTICATE, SET_TOKEN, GET_TOKEN_FROM_LOCALSTORAGE, CHECK_VALID_TOKEN, GET_USER_ROLES_REQUEST, LOGOUT, LOGOUT_AND_LOGIN_AS_USER } from "./actionTypes";
import { ResetPassword, RequestPasswordReset, DeleteUser, SetUser, CreateUserRequest, UpdateUserRequest, GetUserRolesRequest, GetAllUsersRequest, Authenticate, SetToken, CheckValidToken, Logout, LogoutAndLoginAsUser } from './types';
import { setMessage } from '../message/actions';

const authenticationRequest = ( userData : any ) => axios.post<string[]>(window.apiUrl + '/login', userData).then(response => { return response.data})
const checkValidTokenRequest = ( ) => axios.get<string[]>(window.apiUrl + '/isLoggedIn').then(response => { return response.data})
const getAllUsersRequest = () => axios.get<User[]>(window.apiUrl + '/users/all').then(response => { return response.data})
const getAllRolesRequest = () => axios.get<Role[]>(window.apiUrl + '/users/roles').then(response => { return response.data})
const updateUserRequest = ( userData : User, roles : number[] ) => axios.post<string[]>(window.apiUrl + '/users/edit/'+userData.id, { user : userData, roles : roles}).then(response => { return response.data})
const createUserRequest = ( userData : User, roles : number[] ) => axios.post<string[]>(window.apiUrl + '/users/new', { ...userData, roles : roles}).then(response => { return response.data})
const deleteUserRequest = ( userID : number ) => axios.get<string[]>(window.apiUrl + '/users/delete/'+userID).then(response => { return response.data})
const requestPasswordResetRequest = ( email : string ) => axios.post<string[]>(window.apiUrl + '/request-password-reset', { email : email}).then(response => { return response.data})
const resetPasswordRequest = ( data : { password : string, hash : string } ) => axios.post<string[]>(window.apiUrl + '/reset-password', data ).then(response => { return response.data})
function* authenticateRequest( action : Authenticate) {
    try {

        const authenticationResponse : AuthenticationResponse = yield call(authenticationRequest, action.payload);

        yield put(setToken(authenticationResponse.token_data.token_type + ' ' + authenticationResponse.token_data.access_token))
        yield put(setUser(authenticationResponse.user_info[0]));
        yield put(push('/'));
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could not login: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* setTokenToLocalStorage( action : SetToken) {
    try {
        if ( action.payload ) {
            localStorage.setItem('token', action.payload)
        } else {
            localStorage.setItem('token', '')
        }
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could add token to localstorage: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* setUserToLocalStorage( action : SetUser) {
    try {
        if ( action.payload ) {
            localStorage.setItem('user', JSON.stringify(action.payload))
        } else {
            localStorage.setItem('user', '')
        }
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could add user to localstorage: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* logout( action : Logout) {
    try {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        yield put(setUser(undefined))
        yield put(setToken(''))
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could not logout: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* logoutAndLoginAsUser( action : LogoutAndLoginAsUser) {
    try {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.setItem('login_as_user', action.payload);
        yield put(setUser(undefined))
        yield put(setToken(''))
        yield put(push('/'));
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could not logout: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* getTokenFromLocalStorage( action : SetToken) {
    try {
        if ( localStorage.getItem('token') ) {
            const token = localStorage.getItem('token');
            if ( token ){
                yield put(setToken(token))
            }
        }

        if ( localStorage.getItem('user') ) {
            const localStorageUser = localStorage.getItem('user');

            if ( localStorageUser ) {
                const user : any = JSON.parse(localStorageUser);
                if ( user ){
                    yield put(setUser(user))
                }
            }
        }
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could not login: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* checkValidToken( action : CheckValidToken) {
    try {
        const result : User[] = yield call(checkValidTokenRequest);
        if ( result[0] ) {
            const user : User = result[0];

            yield put(setUser(user));
        }
      }catch(e : any) {
          yield put(setToken(null));
          yield put(
              setMessage({
                  notification : {
                      message : 'You have been logged out: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* getAllUsers( action : GetAllUsersRequest) {
    try {
        let result : User[] = yield call(getAllUsersRequest);

        let resultParsed : User[] = [];
        result.forEach((item : User) => {
            item = {
                ...item,
                roleValues : item.roles.map((role : Role) => {
                    return  {
                        value : role.id,
                        label : role.name
                    }
                })
            }

            resultParsed.push(item);
        })

        yield put(setAllUsers(resultParsed));
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all users: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* getAllRoles( action : GetUserRolesRequest) {
    try {
        const result : Role[] = yield call(getAllRolesRequest);
        yield put(setUserRoles({roles : result}));
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all roles: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* resetPassword( action : ResetPassword) {
    try {
        const result : {
            success : boolean,
            email : string
        } = yield call(resetPasswordRequest, action.payload);
        console.log(result);

        if ( result.success ) {
            yield put(authenticate({email : result.email, password: action.payload.password}))
        }
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all roles: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* requestPasswordReset( action : RequestPasswordReset) {
    try {
        const result : any[] = yield call(requestPasswordResetRequest, action.payload);


    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while requesting password reset: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* updateUser( action : UpdateUserRequest) {
    try {
        let roles : number[] = action.payload.roles.map((item : Role) => {
            return item.id;
        })
        yield call(updateUserRequest, action.payload, roles);
        yield put(fetchAllUsers());

        yield put(push('/user'));
        yield put(
            setMessage({
                notification : {
                    message : 'User updated successfully',
                    type : 'success'
                }
            })
        );
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all roles: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* createUser( action : CreateUserRequest) {
    try {
        let roles : number[] = action.payload.roles.map((item : Role) => {
            return item.id;
        })
        yield call(createUserRequest, action.payload, roles);
        yield put(fetchAllUsers());
        yield put(push('/user'));
        yield put(
            setMessage({
                notification : {
                    message : 'User created successfully',
                    type : 'success'
                }
            })
        );
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all roles: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* deleteUser( action : DeleteUser) {
    try {

        yield call(deleteUserRequest, action.payload);
        yield put(fetchAllUsers());
        yield put(push('/user'));
        yield put(
            setMessage({
                notification : {
                    message : 'User deleted successfully',
                    type : 'success'
                }
            })
        );
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while deleting user: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}




function* userSaga() {
  yield all([
      takeLatest(AUTHENTICATE, authenticateRequest),
      takeLatest(SET_TOKEN, setTokenToLocalStorage),
      takeLatest(GET_TOKEN_FROM_LOCALSTORAGE, getTokenFromLocalStorage),
      takeLatest(CHECK_VALID_TOKEN, checkValidToken),
      takeLatest(GET_ALL_USERS_REQUEST, getAllUsers),
      takeLatest(GET_USER_ROLES_REQUEST, getAllRoles),
      takeLatest(UPDATE_USER_REQUEST, updateUser),
      takeLatest(CREATE_USER_REQUEST, createUser),
      takeLatest(SET_USER, setUserToLocalStorage),
      takeLatest(DELETE_USER, deleteUser),
      takeLatest(LOGOUT, logout),
      takeLatest(LOGOUT_AND_LOGIN_AS_USER, logoutAndLoginAsUser),
      takeLatest(REQUEST_PASSWORD_RESET, requestPasswordReset),
      takeLatest(RESET_PASSWORD, resetPassword)
  ]);
}

export default userSaga;
