import {
    FETCH_BATCHES_REQUEST,
    SET_BATCHES,
    GENERATE_BATCH_REQUEST,
    UPDATE_USER_FOR_BATCH_REQUEST,
    SET_CURRENT_BATCHES_PAGE,
    SET_MAX_BATCHES_PAGE,
    SET_BATCHES_WORKING,
    FETCH_BATCH_SETTINGS_REQUEST,
    UPDATE_BATCH_SETTINGS_REQUEST,
    SET_BATCH_SETTINGS,
    FETCH_BATCH_WITH_ORDER_ID_REQUEST
} from "./actionTypes";

import {
    FetchBatchesRequest,
    SetBatches,
    SetBatchesPayload,
    GenerateBatchRequest,
    UpdateUserForbatch,
    UpdateUserForBatchPayload,
    SetCurrentBatchesPage,
    SetMaxBatchesPage,
    SetBatchesWorking,
    FetchBatchSettingsRequest,
    UpdateBatchSettingsRequest,
    UpdateBatchSettingsPayload,
    SetBatchSettingsPayload,
    SetBatchSettings,
    FetchBatchWithOrderIDRequest 
} from "./types";

export const fetchBatchesRequest = (payload : number): FetchBatchesRequest => ({
    type: FETCH_BATCHES_REQUEST,
    payload : payload
});

export const fetchBatchWithOrderIDRequest = (payload : number) : FetchBatchWithOrderIDRequest => ({
    type : FETCH_BATCH_WITH_ORDER_ID_REQUEST,
    payload : payload
})

export const fetchBatchSettingsRequest = (): FetchBatchSettingsRequest => ({
    type: FETCH_BATCH_SETTINGS_REQUEST,
});

export const setBatchSettings = (payload : SetBatchSettingsPayload) : SetBatchSettings => ({
    type : SET_BATCH_SETTINGS,
    payload : payload
})

export const updateBatchSettingsRequest = ( payload : UpdateBatchSettingsPayload ): UpdateBatchSettingsRequest => ({
    type: UPDATE_BATCH_SETTINGS_REQUEST,
    payload: payload
});

export const generateBatchRequest = () : GenerateBatchRequest => ({
    type : GENERATE_BATCH_REQUEST
})

export const setBatches = ( payload: SetBatchesPayload ): SetBatches => ({
    type: SET_BATCHES,
    payload,
});

export const updateUserForBatch = ( payload : UpdateUserForBatchPayload ) : UpdateUserForbatch => ({
    type : UPDATE_USER_FOR_BATCH_REQUEST,
    payload : payload
})

export const setCurrentBatchesPage = ( payload : number ) : SetCurrentBatchesPage => ({
    type : SET_CURRENT_BATCHES_PAGE,
    payload : payload
})

export const setMaxBatchesPage = ( payload : number ) : SetMaxBatchesPage => ({
    type : SET_MAX_BATCHES_PAGE,
    payload : payload
})

export const setBatchesWorking = ( payload : boolean ) : SetBatchesWorking => ({
    type : SET_BATCHES_WORKING,
    payload : payload
})
