import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { CREATE_LOG } from "./actionTypes";
import { fetchSingleBatchLogs } from '../singleBatch/actions';
import { fetchSingleOrderLogs } from '../singleOrder/actions';
import { fetchSingleProductData } from '../singleProduct/actions';
import { setMessage } from '../message/actions';
import { CreateLog } from './types';

const createLogRequest = ( typeID : number, type: string, event : string ) => axios.post<any>(window.apiUrl +  '/log/createLog',  {type_id: typeID, type: type, event: event}).then(response => { return response.data });

//const resp = await this.$http.get(`changeSendingMethodForOrder/${this.showSelectPackageTypeOrder.id}/${packageType.value}`)

function* createLog( action : CreateLog) {

    try {

        yield call(createLogRequest, action.payload.type_id, action.payload.type, action.payload.event);

        if ( action.payload.type === 'batch' ) {
            yield put(fetchSingleBatchLogs(action.payload.type_id));
        }

        if ( action.payload.type === 'order' ) {
            yield put(fetchSingleOrderLogs(action.payload.type_id));
        }

        if ( action.payload.type === 'product' ) {
            yield put(fetchSingleProductData(action.payload.type_id));
        }

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating log: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* batchSaga() {
  yield all([
      takeLatest(CREATE_LOG, createLog),
  ]);
}

export default batchSaga;
