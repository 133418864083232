import React, { Suspense }  from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../redux/config/store';

import { _userHasPermission } from '../../helper/permission';

const BatchForm = React.lazy(() => import('../batch/form'));
const BatchOverview = React.lazy(() => import('../batch/overview'));
const BatchDetail = React.lazy(() => import('../batch/detail'));
const BatchPrintables = React.lazy(() => import('../batch/printables'));

const OrderOverview = React.lazy(() => import('../order/overview'));
const OrderDetail = React.lazy(() => import('../order/detail'));

const ProductOverview = React.lazy(() => import('../product/overview'));
const ProductDetail = React.lazy(() => import('../product/detail'));

const StockOverview = React.lazy(() => import('../stock/overview'));

const UserOverview = React.lazy(() => import('../user/overview'));
const UserForm= React.lazy(() => import('../user/form'));

const IncomingOverview = React.lazy(() => import('../incoming/overview'));
const IncomingForm= React.lazy(() => import('../incoming/form'));

const OutgoingOverview = React.lazy(() => import('../outgoing/overview'));
const OutgoingForm= React.lazy(() => import('../outgoing/form'));
/**
*   @component
*	@Description     Router containing all routes and redirects
*/
const Router = (props : any) => {

    return (
        <Switch>

            {
                _userHasPermission('batch') ?
                    <Route
                        exact
                        path="/"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchOverview key="batch/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }
            {
                _userHasPermission('batch') ?
                    <Route
                        exact
                        path="/batch"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchOverview key="batch/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('batch') ?
                    <Route
                        exact
                        path="/batch/:page"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchOverview key="batch/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }


            {
                _userHasPermission('batch.read') ?
                    <Route
                        exact
                        path="/batch/detail/:batchID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchDetail key="batch/detail" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('batch.read') ?
                    <Route
                        exact
                        path="/batch/detail/:batchID/printables"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchPrintables key="batch/picklist" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('batch') ?
                    <Route
                        exact
                        path="/batch/:page"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchOverview key="batch/overview/paged" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('batch.create') ?
                    <Route
                        exact
                        path="/batch/create"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchForm key="batch/create" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('batch.update') ?
                    <Route
                        exact
                        path="/batch/edit/:batchID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <BatchForm key="batch/edit" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('order') ?
                    <Route
                        exact
                        path="/order"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OrderOverview key="order/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }


            {
                _userHasPermission('order') ?
                    <Route
                        exact
                        path="/order/:page"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OrderOverview key="order/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }


            {
                _userHasPermission('order') ?
                    <Route
                        exact
                        path="/order/detail/:orderID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OrderDetail key="order/detail" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('product') ?
                    <Route
                        exact
                        path="/product"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <ProductOverview key="product/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('product') ?
                    <Route
                        exact
                        path="/product/:page"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <ProductOverview key="product/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('product') ?
                    <Route
                        exact
                        path="/product/detail/:productID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <ProductDetail key="product/detail" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('export') ?
                    <Route
                        exact
                        path="/export"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <StockOverview key="export/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('user') ?
                    <Route
                        exact
                        path="/user"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <UserOverview key="user/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }
            {
                _userHasPermission('user') ?
                    <Route
                        exact
                        path="/user/create"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <UserForm key="user/create" />
                            </Suspense>
                        }
                    />
                :
                    null
            }
            {
                _userHasPermission('user') ?
                    <Route
                        exact
                        path="/user/edit/:userID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <UserForm key="user/edit" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('incoming') ?
                    <Route
                        exact
                        path="/incoming"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <IncomingOverview key="incoming/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }


            {
                _userHasPermission('incoming.create') ?
                    <Route
                        exact
                        path="/incoming/create"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <IncomingForm key="incoming/create" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('incoming.update') ?
                    <Route
                        exact
                        path="/incoming/edit/:incomingID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <IncomingForm key="incoming/edit" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('incoming') ?
                    <Route
                        exact
                        path="/incoming/status/afgerond"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <IncomingOverview key="incoming/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }




            {
                _userHasPermission('outgoing') ?
                    <Route
                        exact
                        path="/outgoing"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OutgoingOverview key="outgoing/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }


            {
                _userHasPermission('outgoing.create') ?
                    <Route
                        exact
                        path="/outgoing/create"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OutgoingForm key="outgoing/create" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('outgoing.update') ?
                    <Route
                        exact
                        path="/outgoing/edit/:outgoingID"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OutgoingForm key="outgoing/edit" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

            {
                _userHasPermission('outgoing') ?
                    <Route
                        exact
                        path="/outgoing/status/afgerond"
                        render={ () =>
                            <Suspense fallback={<div />}>
                                <OutgoingOverview key="outgoing/overview" />
                            </Suspense>
                        }
                    />
                :
                    null
            }

        </Switch>
    )
}


const mapStateToProps = ( state : RootState ) => {

    return {
        MyRoles : state.User.roles,
        MyPermissions : state.User.permissions
    }
}

export default withRouter(connect( mapStateToProps )( Router ));
