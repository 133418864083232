import {
    SET_SINGLE_PRODUCT_DATA,
    SET_SINGLE_PRODUCT_WORKING,
    SET_SINGLE_PRODUCT_LOGS
} from "./actionTypes";


import { SingleProductActions, SingleProductState } from "./types";

const initialState: SingleProductState = {
    data : undefined,
    numberOfUpdates : 0,
    working : false,
    logs : undefined
};

const reducer = (state = initialState, action: SingleProductActions) => {
    switch (action.type) {
        case SET_SINGLE_PRODUCT_DATA:
            return {
                ...state,
                data : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_PRODUCT_LOGS:
            return {
                ...state,
                logs : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_PRODUCT_WORKING:
            return {
                ...state,
                working : action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
