import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { setProducts, setProductsWorking, setMaxProductsPage} from "./actions";
import { GET_PRODUCTS_BY_STOCK_REQUEST, UPDATE_PRODUCT_STOCK_REQUEST, SET_SHIPPING_CLASS_WORKING, UPDATE_PRODUCT_WEIGHT_REQUEST, UPDATE_PRODUCT_SIZES_REQUEST, FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST, UPDATE_PRODUCT_META_REQUEST, UPDATE_PRODUCT_PRICE_REQUEST, UPDATE_PRODUCT_FIELD_REQUEST } from "./actionTypes";
import { GetProductsByStockRequest, UpdateProductStockRequest, SetShippingClassWorking, UpdateProductWeightRequest, UpdateProductSizesRequest, FetchProductsByFilterAndPageRequest,  UpdateProductMetaRequest, UpdateProductPriceRequest, UpdateProductFieldRequest } from "./types";
import { getProducts } from '../config/selectors';
import { setMessage } from '../message/actions';

const getProductsRequest = (filter : string, filterValue : string,  page : number) => axios.get<ProductResponse>(window.apiUrl + '/products?'+filter +'=' +  filterValue + '&page='+page).then(response => { return response.data})
const updateProductMetaDataRequest = (productID : number, updateField : string, updateValue : string) => axios.post<Product>(window.apiUrl + '/products/updateProductMetadata', { id : productID, update_field : updateField, [updateField] : updateValue}).then(response => { return response.data})
const updateProductFieldRequest = (productID : number, updateField : string, updateValue : string) => axios.post<Product>(window.apiUrl + '/products/updateProductField', { id : productID, update_field : updateField, [updateField] : updateValue}).then(response => { return response.data})
const updateProductPriceRequest = (productID : number, updateValue : number) => axios.post<Product>(window.apiUrl + '/products/updateProductPrice', { id : productID,  regular_price : updateValue}).then(response => { return response.data})
const updateProductSizesRequest = (productID : number, length : number, width: number, height: number) => axios.post<Product>(window.apiUrl + '/products/updateProductDimensions', { id : productID,  length : length.toString(), width: width.toString(), height: height.toString()}).then(response => { return response.data})
const updateProductWeightRequest = (productID : number, weight: number) => axios.post<Product>(window.apiUrl + '/products/updateProductWeight', { id : productID,  weight : weight}).then(response => { return response.data})
const updateStockRequest = (productID : number, stock: number, oldStock : number) => axios.put<Product>(window.apiUrl + '/products/'+productID, {  stock: stock, oldStock : oldStock}).then(response => { return response.data})
const getProductsByStockRequest = (minStock : number, maxStock : number, filename : string) => axios.post<string>(window.apiUrl + '/products/getProductsByStock', {  minStock : minStock, maxStock : maxStock, filename : filename }, { responseType : 'blob' }).then(response => { return response.data})

function* fetchProducts(action : FetchProductsByFilterAndPageRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setProductsWorking(true));


        const response: ProductResponse = yield call(getProductsRequest, action.payload.filter, action.payload.filterValue,  action.payload.page);

        if ( response.products ) {
            yield put(
                setProducts({
                    products: response.products,
                })
            )
            yield put(setMaxProductsPage(response.maxPages));
        }

        yield put(setProductsWorking(false));

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching products: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateProductMeta(action : UpdateProductMetaRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1) {
            const product : Product = products[productIndex];

            const productMetaIndex = product.meta_data.findIndex((item : ProductMeta ) => item.key === action.payload.metaField);
            if ( productMetaIndex > -1) {
                product.meta_data[productMetaIndex].value = action.payload.value;
            } else {
                product.meta_data.push({
                    id : 9999999999,
                    key : action.payload.metaField,
                    value : action.payload.value
                })
            }

            products[productIndex] = product;

            yield put(
                setProducts({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product meta ('+ action.payload.metaField+') updated successfully',
                        type : 'success'
                    }
                })
            );

            let result : Product = yield call(updateProductMetaDataRequest, action.payload.productID, action.payload.metaField, action.payload.value);


            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product meta: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}
function* setShippingClassWorking(action : SetShippingClassWorking) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload);

        if ( productIndex > -1 ) {

            products[productIndex].shipping_class_working = true;
            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while setting shipping class working: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}
function* updateProductField(action : UpdateProductFieldRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1 ) {

            let newValue = action.payload.value;
            if ( action.payload.productField === 'shipping_class' ) {
                newValue = action.payload.value === 'bbp' ? 507 : action.payload.value === 'ogk' ? 4502 : 0;
                products[productIndex]['shipping_class_id'] = newValue;
            } else {
                products[productIndex][action.payload.productField] = newValue;
            }


            yield put(
                setProducts({
                    products: products
                })
            )


            yield put(
                setMessage({
                    notification : {
                        message : 'Product field ('+ action.payload.productField+') updated successfully',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateProductFieldRequest, action.payload.productID, action.payload.productField, action.payload.value);
            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product field: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* updateProductPrice(action : UpdateProductPriceRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1 ) {
            products[productIndex].regular_price = action.payload.value;
            yield put(
                setProducts({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product price updated successfully',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateProductPriceRequest, action.payload.productID, action.payload.value);
            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product price: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* updateProductSizes(action : UpdateProductSizesRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1 ) {
            products[productIndex].dimensions.length = action.payload.values.length;
            products[productIndex].dimensions.width = action.payload.values.width;
            products[productIndex].dimensions.height = action.payload.values.height;
            yield put(
                setProducts({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product dimensions updated successfully',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateProductSizesRequest, action.payload.productID, action.payload.values.length, action.payload.values.width, action.payload.values.height);

            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product dimensions: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* updateProductWeight(action : UpdateProductWeightRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1 ) {
            products[productIndex].weight = action.payload.weight;

            yield put(
                setProducts({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product weight updated successfully',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateProductWeightRequest, action.payload.productID, action.payload.weight);


            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product weight: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateProductStock(action : UpdateProductStockRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : Product[] = yield select(getProducts);

        const productIndex : number  = products.findIndex((item : Product) => item.id === action.payload.productID);

        if ( productIndex > -1 ) {

            products[productIndex].stock_quantity = action.payload.stock;

            yield put(
                setProducts({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product stock changed',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateStockRequest, action.payload.productID, action.payload.stock, action.payload.oldStock);


            products[productIndex] = result;

            yield put(
                setProducts({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while moving product stock: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* getProductsByStock(action : GetProductsByStockRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        let today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()
        const filename = 'voorraadlijst_' + mm + '-' + dd + '-' + yyyy;

        const result : Blob = yield call(getProductsByStockRequest, action.payload.minStock, action.payload.maxStock, filename);

        const blob = result;
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename + '.xlsx'
        link.click()


    }catch(e : any) {
        yield put(setProductsWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching suppliers: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* productSaga() {
  yield all([
      takeLatest(FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST, fetchProducts),
      takeLatest(UPDATE_PRODUCT_META_REQUEST, updateProductMeta),
      takeLatest(UPDATE_PRODUCT_PRICE_REQUEST, updateProductPrice),
      takeLatest(UPDATE_PRODUCT_FIELD_REQUEST, updateProductField),
      takeLatest(UPDATE_PRODUCT_SIZES_REQUEST, updateProductSizes),
      takeLatest(UPDATE_PRODUCT_WEIGHT_REQUEST, updateProductWeight),
      takeLatest(SET_SHIPPING_CLASS_WORKING, setShippingClassWorking),
      takeLatest(UPDATE_PRODUCT_STOCK_REQUEST, updateProductStock),
      takeLatest(GET_PRODUCTS_BY_STOCK_REQUEST, getProductsByStock)
  ]);
}

export default productSaga;
