import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setOutgoingGoodsWorking, setOutgoingGoods, getOutgoingGoodsRequest } from "./actions";
import { DELETE_OUTGOING_GOODS_REQUEST, CREATE_OUTGOING_GOODS_REQUEST, GET_OUTGOING_GOODS_REQUEST, UPDATE_OUTGOING_GOODS_REQUEST } from "./actionTypes";
import {  DeleteOutgoingGoodsRequest, CreateOutgoingGoodsRequest, GetOutgoingGoodsRequest, UpdateOutgoingGoodsRequest } from './types';
import { setMessage } from '../message/actions';


const getAllOutgoingGoodsRequest = () => axios.get<OutgoingGood[]>(window.apiUrl + '/outgoing/all').then(response => { return response.data})
const createOutgoingGoodsRequest = ( OutgoingGoodsData : OutgoingGood) => axios.post<string[]>(window.apiUrl + '/outgoing/create', { ...OutgoingGoodsData}).then(response => { return response.data})
const updateOutgoingGoodsRequest = ( OutgoingGoodsData : OutgoingGood, id : number) => axios.post<string[]>(window.apiUrl + '/outgoing/update/'+id, { ...OutgoingGoodsData}).then(response => { return response.data})

function* getOutgoingGoods( action : GetOutgoingGoodsRequest) {
    try {
        yield put(setOutgoingGoodsWorking(true));
        let result : OutgoingGood[] = yield call(getAllOutgoingGoodsRequest);
        yield put(setOutgoingGoods(result));
        yield put(setOutgoingGoodsWorking(false));
    }catch(e : any) {
        yield put(setOutgoingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all Outgoing goods: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* updateOutgoingGoods( action : UpdateOutgoingGoodsRequest) {
    try {
        yield put(setOutgoingGoodsWorking(true));
        if ( action.payload.id ) {


            yield call(updateOutgoingGoodsRequest, action.payload, action.payload.id);

            yield put(getOutgoingGoodsRequest());
            yield put(
                setMessage({
                    notification : {
                        message : 'Outgoing good updated successfully:',
                        type : 'success'
                    }
                })
            );
        }
        yield put(setOutgoingGoodsWorking(false));
    }catch(e : any) {
         yield put(setOutgoingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while updating Outgoing good: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* createOutgoingGoods( action : CreateOutgoingGoodsRequest) {
    yield put(setOutgoingGoodsWorking(true));
    try {


        yield call(createOutgoingGoodsRequest, action.payload);
        yield put(getOutgoingGoodsRequest());
        yield put(
            setMessage({
                notification : {
                    message : 'Outgoing good created successfully:',
                    type : 'success'
                }
            })
        );
        yield put(setOutgoingGoodsWorking(false));
    }catch(e : any) {
        yield put(setOutgoingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while creating Outgoing good: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* deleteOutgoingGoods( action : DeleteOutgoingGoodsRequest) {
    try {
        /*
        const result : User = yield call(deleteUserRequest, action.payload);
        yield put(fetchAllUsers());
        yield put(push('/user'));
        yield put(
            setMessage({
                notification : {
                    message : 'User deleted successfully',
                    type : 'success'
                }
            })
        );*/
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while deleting user: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}




function* userSaga() {
  yield all([
      takeLatest(GET_OUTGOING_GOODS_REQUEST, getOutgoingGoods),
      takeLatest(CREATE_OUTGOING_GOODS_REQUEST, createOutgoingGoods),
      takeLatest(UPDATE_OUTGOING_GOODS_REQUEST, updateOutgoingGoods),
      takeLatest(DELETE_OUTGOING_GOODS_REQUEST, deleteOutgoingGoods),
  ]);
}

export default userSaga;
