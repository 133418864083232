import { SET_MESSAGE } from "./actionTypes";
import { MessageActions, MessageState } from "./types";
const initialState: MessageState = {
    notification : {
        message : '',
        type : ''
    },
};

const messageReducer = (state = initialState, action: MessageActions) => {
    switch (action.type) {
        case SET_MESSAGE:
            return {
                ...state,
                notification: action.payload.notification,
            };
        default:
            return {
                ...state,
            };
    }
};

export default messageReducer;
