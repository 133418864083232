import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setSuppliers, setCategories} from "./actions";
import { FETCH_SUPPLIERS_REQUEST, FETCH_CATEGORIES_REQUEST } from "./actionTypes";
import { FetchSuppliersRequest, FetchCategoriesRequest } from "./types";

import { setMessage } from '../message/actions';

const getSuppliersRequest = () => axios.get<Supplier[]>(window.apiUrl + '/products/suppliers/all').then(response => { return response.data})
const getCategoriesRequest = () => axios.get<Supplier[]>(window.apiUrl + '/products/categories/all').then(response => { return response.data})

function* fetchSuppliers(action : FetchSuppliersRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        const suppliers : Supplier[] = yield call(getSuppliersRequest);
        yield put(
            setSuppliers(suppliers)
        )
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching suppliers: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* fetchCategories(action : FetchCategoriesRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        const categories : Category[] = yield call(getCategoriesRequest);
        yield put(
            setCategories(categories)
        )
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching categories: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* productSaga() {
  yield all([
      takeLatest(FETCH_SUPPLIERS_REQUEST, fetchSuppliers),
      takeLatest(FETCH_CATEGORIES_REQUEST, fetchCategories),
  ]);
}

export default productSaga;
