import {
    FETCH_SINGLE_ORDER_DATA,
    SET_SINGLE_ORDER_DATA,
    SET_SINGLE_ORDER_WORKING,
    FETCH_SINGLE_ORDER_LOGS,
    SET_SINGLE_ORDER_LOGS
} from "./actionTypes";

import {
    FetchSingleOrderData,
    SetSingleOrderData,
    SetSingleOrderWorking,
    FetchSingleOrderLogs,
    SetSingleOrderLogs
} from "./types";


export const fetchSingleOrderData = (payload : number ) : FetchSingleOrderData => ({
    type : FETCH_SINGLE_ORDER_DATA,
    payload : payload
})

export const setSingleOrderData = ( payload: SingleOrderData | undefined ): SetSingleOrderData => ({
    type: SET_SINGLE_ORDER_DATA,
    payload : payload,
});

export const setSingleOrderWorking = ( payload : boolean ) : SetSingleOrderWorking => ({
    type : SET_SINGLE_ORDER_WORKING,
    payload : payload
})

export const fetchSingleOrderLogs = (payload : number ) : FetchSingleOrderLogs => ({
    type : FETCH_SINGLE_ORDER_LOGS,
    payload : payload
})

export const setSingleOrderLogs = ( payload: SingleOrderLogs | undefined ): SetSingleOrderLogs => ({
    type: SET_SINGLE_ORDER_LOGS,
    payload,
});
