import {
    SET_OUTGOING_GOODS,
    SET_OUTGOING_GOODS_WORKING
} from "./actionTypes";

import { OutgoingGoodActions, OutgoingGoodState } from "./types";

const initialState: OutgoingGoodState = {
    outgoingGoods : [],
    numberOfUpdates : 0,
    working : false
};

const reducer = (state = initialState, action: OutgoingGoodActions) => {
    switch (action.type) {
        case SET_OUTGOING_GOODS:
            return {
                ...state,
                outgoingGoods : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1
            }
        case SET_OUTGOING_GOODS_WORKING:
            return {
                ...state,
                working : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
