import React, { useEffect, useState } from 'react';
//import { NavLink } from 'react-router-dom';
import Header from '../header';
import Router from '../router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReduxConfig  from "../../redux/config/store";
import Boot from '../../redux/config/boot';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../redux/config/store';
import FullPageLoader from '../common/fullPageLoader';

/**
*   Main app component. Contains navigation and router. Runs the Redux boot function once.
*   @component
*/
const App: React.FC = (props : any) => {
    const [ bootRan, setBootRan ] = useState<boolean>(false);

    useEffect(() =>  {
        if ( bootRan === false) {
            Boot(ReduxConfig.store.dispatch);
            setBootRan(true);
        }

        return () => {
            //props.actions.setSearchTerm(undefined);
        }
    }, [ bootRan ]);

    /*
    const _renderButtonsByPermission = () => {
        if ( props.MyRoles.includes('Reservation.Admin') ) { 
            return <li><NavLink to="/permission/overview">Permissions</NavLink></li>
        }
    }*/

    return (
        <React.Fragment>
            <FullPageLoader />
            <ToastContainer />
            <Header />
            <main>
                <div className="container">
                    <div className="main__inner__grid">
                        <div className="main__inner__grid__component">
                            <Router />
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}

const mapStateToProps = ( state : RootState ) => {

    return {
        MyRoles : state.User.roles,
        MyPermissions : state.User.permissions
    }
}

export default withRouter(connect( mapStateToProps )( App ));
