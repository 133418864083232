import { all } from 'redux-saga/effects';
import batchSaga from '../batch/saga';
import orderSaga from '../order/saga';
import singleBatchSaga from '../singleBatch/saga';
import singleOrderSaga from '../singleOrder/saga';
import userSaga from '../user/saga';
import messageSaga from '../message/saga';
import productSaga from '../product/saga';
import singleProductSaga from '../singleProduct/saga';
import stockSaga from '../stock/saga';
import logSaga from '../log/saga';
import attributeSaga from '../attribute/saga';
import incomingGoodsSaga from '../incomingGoods/saga';
import outgoingGoodsSaga from '../outgoingGoods/saga';
export default function* rootSaga() {
	yield all([
		batchSaga(),
		orderSaga(),
		singleBatchSaga(),
		singleOrderSaga(),
		userSaga(),
		messageSaga(),
		logSaga(),
		productSaga(),
		singleProductSaga(),
		stockSaga(),
		attributeSaga(),
		incomingGoodsSaga(),
		outgoingGoodsSaga()
	]);
}
