import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest, select} from "redux-saga/effects";
import { setSingleOrderData, setSingleOrderWorking, setSingleOrderLogs } from "./actions";
import { getSingleOrderData } from '../config/selectors';
import { FETCH_SINGLE_ORDER_DATA, SET_SINGLE_ORDER_DATA, FETCH_SINGLE_ORDER_LOGS } from "./actionTypes";
import { setMessage } from '../message/actions';
import { FetchSingleOrderData, SetSingleOrderData, FetchSingleOrderLogs } from './types';


const getOrderByIDRequest = ( orderID : number ) => axios.post<any>(window.apiUrl +  '/getOrderById/' + orderID).then(response => { return response.data?.order });
const getOrderNumberForEss = ( orderID : number ) => axios.get<string | undefined>(window.apiUrl +  '/getOrderNumberForEss/' + orderID).then(response => { return response.data });
const getSingleOrderLogs = ( orderID : number ) => axios.get<Log[]>(window.apiUrl +  '/log/getLogs/order/' + orderID).then(response => { return response.data });

//const resp = await this.$http.get(`changeSendingMethodForOrder/${this.showSelectPackageTypeOrder.id}/${packageType.value}`)

function* fetchOrderByID( action : FetchSingleOrderData) {
    try {
        yield put(setSingleOrderWorking(true));

        const order : Order = yield call(getOrderByIDRequest, action.payload);
        let orderData : SingleOrderData = {
            orderID : action.payload,
            orderData : order
        }

        yield put(setSingleOrderData(orderData));
        yield put(setSingleOrderWorking(false));

    }catch(e : any) {
        yield put(setSingleOrderWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching order by ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* extractExternalCode( action : SetSingleOrderData) {
    try {
        if ( action.payload ) {
            const order : Order = action.payload.orderData;

            if ( !order.externalCode ) {

                let externalCode : string | undefined = undefined;

                if ( order.coupon_lines.length > 0 ) {
                    if (order.coupon_lines[0].code.includes('bol.com')) {
                        externalCode = order.coupon_lines[0].code.substring(
                            order.coupon_lines[0].code.lastIndexOf('>') + 1,
                            order.coupon_lines[0].code.lastIndexOf('(')
                        )
                    }
                }

                if ( !externalCode ) {
                    const response : string | undefined = yield call(getOrderNumberForEss, order.id);

                    if ( response && typeof response === 'string') {
                        externalCode = response;
                    }
                }

                if ( externalCode ) {
                    const currentOrder : SingleOrderData = yield select(getSingleOrderData);

                    if ( parseInt(currentOrder.orderID.toString()) === order.id ) {

                        let orderData : SingleOrderData = {
                            orderID : order.id,
                            orderData : {
                                ...order,
                                externalCode : externalCode
                            }
                        }
                        yield put(setSingleOrderData(orderData));
                    }
                }
            }



        }

    }catch(e : any) {
        yield put(setSingleOrderWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while extracting external order code for ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * fetchOrderLogs( action: FetchSingleOrderLogs ) {
    try {

        let singleBatchLogs: Log[] = yield call(getSingleOrderLogs, action.payload);

        let logs : SingleOrderLogs = {
            orderID : parseInt(action.payload.toString()),
            logs : singleBatchLogs
        }
    
        yield put(setSingleOrderLogs(logs));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching order logs: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* batchSaga() {
  yield all([
      takeLatest(FETCH_SINGLE_ORDER_DATA, fetchOrderByID),
      takeLatest(SET_SINGLE_ORDER_DATA, extractExternalCode),
      takeLatest(FETCH_SINGLE_ORDER_LOGS, fetchOrderLogs)
  ]);
}

export default batchSaga;
