import { RootState } from './store';

// -- Selectors are used in saga-selects, so we'll only get the values we need.
export const getBatches = (state : RootState) : Batch[] => state.Batch.batches;
export const getSingleBatchData = ( state : RootState ) : SingleBatchData | undefined => state.SingleBatch.data;
export const getSingleBatchLabelJobID = (state : RootState) : number | undefined => state.SingleBatch.labelJobRunning;
export const getIncomingGoodsData = (state : RootState) : IncomingGood[] => state.IncomingGoods.incomingGoods;
export const getBatchesCurrentPage = ( state : RootState ) : number => state.Batch.currentPage;
export const getUsers = ( state : RootState ) : User[] => state.User.users;
export const getSingleOrderData = ( state : RootState ) : SingleOrderData | undefined => state.SingleOrder.data;
export const getFetchByOrderStatus = ( state : RootState ) : string => state.Order.fetchByOrderStatus;
export const getProducts = ( state : RootState ) : Product[] => state.Product.products;
export const getStockProducts = ( state : RootState ) : StockProduct[] => state.Stock.products;
