import { combineReducers } from "redux";
import BatchReducer from '../batch/reducer';
import OrderReducer from '../order/reducer';
import SingleBatchReducer from '../singleBatch/reducer';
import UserReducer from '../user/reducer';
import MessageReducer from '../message/reducer';
import SingleOrderReducer from '../singleOrder/reducer';
import ProductReducer from '../product/reducer';
import SingleProductReducer from '../singleProduct/reducer';
import StockReducer from '../stock/reducer';
import AttributeReducer from '../attribute/reducer';
import IncomingGoodsReducer from '../incomingGoods/reducer';
import OutgoingGoodsReducer from '../outgoingGoods/reducer';
//import LogReducer from '../log/reducer';
import { connectRouter } from 'connected-react-router'

const rootReducer = (history : any) => combineReducers({
    router: connectRouter(history),
    Batch : BatchReducer,
    Order : OrderReducer,
    SingleBatch : SingleBatchReducer,
    SingleOrder : SingleOrderReducer,
    User : UserReducer,
    Message : MessageReducer,
    Product : ProductReducer,
    SingleProduct : SingleProductReducer,
    Stock : StockReducer,
    Attribute : AttributeReducer,
    IncomingGoods : IncomingGoodsReducer,
    OutgoingGoods : OutgoingGoodsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
