import {
    SET_ORDERS,
    SET_MAX_ORDERS_PAGE,
    SET_CURRENT_ORDERS_PAGE,
    SET_ORDERS_WORKING,
    SET_FETCH_BY_ORDER_STATUS
} from "./actionTypes";

import { OrderActions, OrderState } from "./types";

const initialState: OrderState = {
    numberOfUpdates : 0,
    maxPages : 0,
    currentPage : 1,
    orders: [],
    working : false,
    fetchByOrderStatus : 'processing'
};

const reducer = (state = initialState, action: OrderActions) => {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                numberOfUpdates : state.numberOfUpdates + 1,
                orders: action.payload.orders,
            };
        case SET_FETCH_BY_ORDER_STATUS :
            return {
                ...state,
                fetchByOrderStatus : action.payload
            }
        case SET_ORDERS_WORKING:
            return {
                ...state,
                working: action.payload
            };
        case SET_MAX_ORDERS_PAGE:
            return {
                ...state,
                maxPages : action.payload
            }
        case SET_CURRENT_ORDERS_PAGE:
            return {
                ...state,
                currentPage : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
