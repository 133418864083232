import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest} from "redux-saga/effects";
import { setSingleProductData, setSingleProductWorking, setSingleProductLogs } from "./actions";
import { FETCH_SINGLE_PRODUCT_DATA } from "./actionTypes";
import { setMessage } from '../message/actions';
import { FetchSingleProductData } from './types';

const getProductByIDRequest = ( productID : number ) => axios.get<Product>(window.apiUrl +  '/products/' + productID).then(response => { return response.data });

//const resp = await this.$http.get(`changeSendingMethodForOrder/${this.showSelectPackageTypeOrder.id}/${packageType.value}`)

function* fetchProductByID( action : FetchSingleProductData) {
    try {
        yield put(setSingleProductWorking(true));

        const product : Product = yield call(getProductByIDRequest, action.payload);
        let productData : SingleProductData = {
            productID : action.payload,
            productData : product
        }

        if ( product.log ) {
            console.log("HERE");
            console.log(product.log);
            let logs : SingleProductLogs = {
                productID : parseInt(action.payload.toString()),
                logs : product.log
            }

            yield put(setSingleProductLogs(logs));
        }

        yield put(setSingleProductData(productData));
        yield put(setSingleProductWorking(false));

    }catch(e : any) {
        yield put(setSingleProductWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching product by ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* batchSaga() {
  yield all([
      takeLatest(FETCH_SINGLE_PRODUCT_DATA, fetchProductByID),
  ]);
}

export default batchSaga;
