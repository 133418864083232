import React, { useState }  from 'react';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/img/Logo_MT.jpeg';
import { _userHasPermission } from '../../helper/permission';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/user/actions';
import * as messageActions from '../../redux/message/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon}  from '@fortawesome/react-fontawesome';
import {  faDoorOpen, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';


// ...

/**
*   Header, layout component
*   @component
*/
const Header = (props : any) => {
     const history = useHistory();
    const [ headerOpen, setHeaderOpen ] = useState<Boolean>(false);
    const [ selectValue, setSelectValue ] = useState<string>('');
    const [ userPopupVisible, setUserPopupVisible ] = useState<boolean>(false);

    const _mapUser = (item : User, key: number) => {
        return <option value={item.email}>{item.name}</option>
    }

    const loginAsUser = () => {
        //history.push('/registrationStep2')
        props.actions.logoutAndLoginAsUser(selectValue);
    }

    const logout = () => {
        //Evan123!@
        props.actions.logout();
    }

    return (
        <header className="header">
            <div className="container">
                <div className="header__inner">
                    <div className="header__inner__grid">
                        <div className="header__inner__grid__logo">
                            <div className="header__inner__grid__logo__wrapper">
                                <NavLink to="/">
                                    <img alt="Logo" src={logo} />
                                </NavLink>
                            </div>
                        </div>
                        <div className="header__inner__grid__hamburger">
                            <button className={(headerOpen ? 'opened': '')} onClick={() => setHeaderOpen(!headerOpen)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        <div className={"header__inner__grid__navigation " + (headerOpen ? 'header__inner__grid__navigation--opened': '')}>
                            <nav onClick={() => setHeaderOpen(false)}>
                                <ul>
                                    { _userHasPermission('batch') ? <li><NavLink to="/batch">Batches</NavLink></li> : null }
                                    { _userHasPermission('order') ? <li><NavLink to="/order">Orders</NavLink></li> : null }
                                    { _userHasPermission('product') ? <li><NavLink to="/product">Products</NavLink></li> : null }
                                    { _userHasPermission('export') ? <li><NavLink to="/export">Export</NavLink></li> : null }
                                    { _userHasPermission('user') ? <li><NavLink to="/user">Users</NavLink></li> : null }
                                    { _userHasPermission('incoming') ? <li><NavLink to="/incoming">Inkomend</NavLink></li> : null }
                                </ul>
                            </nav>
                        </div>

                        <div className="header__inner__grid__account">
                            <div className="header__inner__grid__account__inner" onClick={() => setUserPopupVisible(!userPopupVisible)}>{ props.User.name }
                                <FontAwesomeIcon icon={faDoorOpen} />
                            </div>
                            { userPopupVisible ?
                                <div className="header__inner__grid__account__user-popup">
                                    <div className="header__inner__grid__account__user-popup__dismiss" onClick={() => setUserPopupVisible(!userPopupVisible)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                    <select onChange={(e) => setSelectValue(e.target.value)}>
                                        <option value="">Selecteer gebruiker</option>
                                        {props.Users.map(_mapUser)}
                                    </select>

                                    { selectValue ? <button onClick={() => loginAsUser()}>Inloggen als gebruiker</button> : <button onClick={() => logout()}>Uitloggen</button> }

                                </div>
                            : null }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = ( state : RootState ) => {
    return {
        User : state.User.user,
        Users : state.User.users
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions, ...messageActions}, dispatch) })

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( Header ));
