import {
    FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST,
    SET_PRODUCTS,
    SET_PRODUCTS_WORKING,
    SET_MAX_PRODUCTS_PAGE,
    UPDATE_PRODUCT_META_REQUEST,
    UPDATE_PRODUCT_FIELD_REQUEST,
    UPDATE_PRODUCT_PRICE_REQUEST,
    UPDATE_PRODUCT_SIZES_REQUEST,
    UPDATE_PRODUCT_WEIGHT_REQUEST,
    SET_SHIPPING_CLASS_WORKING,
    UPDATE_PRODUCT_STOCK_REQUEST,
    GET_PRODUCTS_BY_STOCK_REQUEST
} from "./actionTypes";

import {
    FetchProductsByFilterAndPagePayload,
    FetchProductsByFilterAndPageRequest,
    SetProductsPayload,
    SetProducts,
    SetProductsWorking,
    SetMaxProductsPage,
    UpdateProductMetaPayload,
    UpdateProductMetaRequest,
    UpdateProductPricePayload,
    UpdateProductPriceRequest,
    UpdateProductFieldRequest,
    UpdateProductFieldPayload,
    UpdateProductSizesRequest,
    UpdateProductSizesPayload,
    UpdateProductWeightRequest,
    UpdateProductWeightPayload,
    SetShippingClassWorking,
    UpdateProductStockPayload,
    UpdateProductStockRequest,
    GetProductsByStockRequest,
    GetProductsByStockPayload
} from "./types";

export const fetchProductsByFilterAndPageRequest = (payload : FetchProductsByFilterAndPagePayload): FetchProductsByFilterAndPageRequest => ({
    type: FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST,
    payload : payload
});

export const setShippingClassWorking = ( payload: number ): SetShippingClassWorking => ({
    type: SET_SHIPPING_CLASS_WORKING,
    payload,
});

export const getProductsByStockRequest = (payload : GetProductsByStockPayload): GetProductsByStockRequest => ({
    type : GET_PRODUCTS_BY_STOCK_REQUEST,
    payload : payload
})


export const setProducts = ( payload: SetProductsPayload ): SetProducts => ({
    type: SET_PRODUCTS,
    payload,
});

export const setProductsWorking = ( payload: boolean ): SetProductsWorking => ({
    type: SET_PRODUCTS_WORKING,
    payload,
});

export const setMaxProductsPage = ( payload : number ) : SetMaxProductsPage => ({
    type : SET_MAX_PRODUCTS_PAGE,
    payload : payload
})

export const updateProductMetaRequest = (payload : UpdateProductMetaPayload ) : UpdateProductMetaRequest => ({
    type : UPDATE_PRODUCT_META_REQUEST,
    payload : payload
})

export const updateProductFieldRequest = (payload : UpdateProductFieldPayload ) : UpdateProductFieldRequest => ({
    type : UPDATE_PRODUCT_FIELD_REQUEST,
    payload : payload
})

export const updateProductPriceRequest = ( payload : UpdateProductPricePayload ) : UpdateProductPriceRequest => ({
    type : UPDATE_PRODUCT_PRICE_REQUEST,
    payload : payload
})

export const updateProductWeightRequest = ( payload : UpdateProductWeightPayload ) : UpdateProductWeightRequest => ({
    type : UPDATE_PRODUCT_WEIGHT_REQUEST,
    payload : payload
})

export const updateProductSizesRequest = ( payload : UpdateProductSizesPayload ) : UpdateProductSizesRequest => ({
    type : UPDATE_PRODUCT_SIZES_REQUEST,
    payload : payload
})


export const updateProductStockRequest = ( payload : UpdateProductStockPayload ) : UpdateProductStockRequest => ({
    type : UPDATE_PRODUCT_STOCK_REQUEST,
    payload : payload
})
