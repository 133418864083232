import {
    SET_SINGLE_ORDER_DATA,
    SET_SINGLE_ORDER_WORKING,
    SET_SINGLE_ORDER_LOGS
} from "./actionTypes";


import { SingleOrderActions, SingleOrderState } from "./types";

const initialState: SingleOrderState = {
    data : undefined,
    numberOfUpdates : 0,
    working : false,
    logs : undefined
};

const reducer = (state = initialState, action: SingleOrderActions) => {
    switch (action.type) {
        case SET_SINGLE_ORDER_DATA:
            return {
                ...state,
                data : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_ORDER_LOGS:
            return {
                ...state,
                logs : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_ORDER_WORKING:
            return {
                ...state,
                working : action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
