import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
import { NavLink } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import ResetPassword from './resetPassword';

const Login = ( props : any) => {
    const [ password, setPassword ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ tokenFromLocalStorageChecked, setTokenFromLocalStorageChecked ] = useState<boolean>(false);
    useEffect(() => {
        // -- Check authorization

        // -- Check authorization
        if ( !tokenFromLocalStorageChecked ) {
            setTokenFromLocalStorageChecked(true);
            props.actions.getTokenFromLocalStorage();
        }

        if ( localStorage.getItem('login_as_user') ) {
            const email = localStorage.getItem('login_as_user');
            if ( email ) {
                setEmail(email);
                localStorage.removeItem('login_as_user');
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.location]);

   const _formSubmit = (e : React.SyntheticEvent) : void => {
       e.preventDefault();

       props.actions.authenticate({
           email : email,
           password : password
       })
   }

   const _resetPasswordRequest = (e : React.SyntheticEvent) : void => {
       e.preventDefault();
       setSuccess(true);
       props.actions.requestPasswordReset(email)
   }

   const _resetPassword = (e : React.SyntheticEvent) : void => {
       e.preventDefault();

       props.actions.requestPasswordReset(email)
   }

    return (
        <React.Fragment>
            <div className="login-page">
                <div className="login-page__form-wrapper">
                <Switch>


                    <Route
                        exact
                        path="/forgot-password"
                        render={ () =>
                            <form onSubmit={_resetPasswordRequest}>
                                <input type="email" placeholder="E-mailadres" value={email} onChange={(e) => { setEmail(e.target.value); setSuccess(false)}} />

                                    { success ? <><p>Herstellink aangevraagd</p><NavLink to="/">Terug</NavLink></> : <button>Wachtwoord resetten</button> }
                            </form>
                        }
                    />

                    <Route
                        exact
                        path="/reset-password/:hash"
                        render={ () =>
                            <ResetPassword />
                        }
                    />

                    <Route

                        path="/"
                        render={ () =>
                            <form onSubmit={_formSubmit}>
                                <input type="email" placeholder="E-mailadres" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <input type="password" placeholder="Wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <button>Login</button>
                                <NavLink to="/forgot-password">Wachtwoord vergeten? </NavLink>
                            </form>
                        }
                    />
                </Switch>


                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    return {
        location : state.router.location
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( Login ));
