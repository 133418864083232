import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest, select, fork, take, cancel, delay, takeEvery} from "redux-saga/effects";
import { setSingleBatchWorking, setSingleBatchLogs, startBatchLabelWatchJob, stopBatchLabelWatchJob, setBatchLabelJobRunning, setSingleBatchData, setSingleBatchPacklist, setSingleBatchPicklist, fetchSingleBatchData, setSingleBatchLabelData } from "./actions";
import { getSingleBatchData, getSingleBatchLabelJobID } from '../config/selectors';
import { FETCH_SINGLE_BATCH_LOGS, UPDATE_SHIPPING_METHOD_FOR_ORDER, UPDATE_BATCH_PICKED, REPAIR_MYPARCEL_LABELS, SET_SINGLE_BATCH_LABEL_DATA, STOP_BATCH_LABEL_WATCH_JOB, START_BATCH_LABEL_WATCH_JOB, SET_SINGLE_BATCH_DATA, FETCH_SINGLE_BATCH_DATA, FETCH_SINGLE_BATCH_PACKLIST, FETCH_SINGLE_BATCH_PICKLIST, GENERATE_MYPARCEL_LABELS } from "./actionTypes";
import { setMessage } from '../message/actions';
import { FetchSingleBatchLogs, UpdateShippingMethodForOrder, UpdateBatchPicked, RepairMyParcelLabels, SetSingleBatchData, SetSingleBatchLabelData, FetchSingleBatchData, FetchSingleBatchPackList, FetchSingleBatchPickList, GenerateMyParcelLabels } from './types';
import type { Task } from 'redux-saga';

const getBatchByIDRequest = ( batchID : number ) => axios.get<any>(window.apiUrl +  '/getBatchById/' + batchID).then(response => { return response.data?.batch });
const getPickListForBatch = ( batchID : number ) => axios.get<PickList>(window.apiUrl +  '/getPickListForBatch/' + batchID).then(response => { return response.data });
const getPackListForBatch = ( batchID : number ) => axios.get<PackListItem[]>(window.apiUrl +  '/getPackListForBatch/' + batchID).then(response => { return response.data });
const generateMyParcelLabelsRequest = ( batchID : number ) => axios.get<any>(window.apiUrl +  '/generateShipmentLabelsFromMyParcel/' + batchID).then(response => { return response.data?.labelData });
const getBatchesRetrievedRequest = (batchID : number) => axios.get<SingleBatchLabelData>(window.apiUrl +  '/getAmountOfBatchesRetrievedFromMyParcel/' + batchID).then(response => { return response.data });
const repairPostNlLabelsRequest = (batchID : number) => axios.get<any>(window.apiUrl +  '/repairPostNlLabels/' + batchID).then(response => { return response.data });
const updateBatchPickedRequest = ( batchID : number ) => axios.get<any>(window.apiUrl +  '/updateBatchIsPicked/' + batchID).then(response => { return response.data });
const queueMailsRequest = ( batchID : number ) => axios.get<any>(window.apiUrl +  '/queueMails/' + batchID).then(response => { return response.data });
const updateShippingMethodForOrderRequest = ( orderID : number, packageTypeID : number ) => axios.get<number>(window.apiUrl +  '/changeSendingMethodForOrder/' + orderID + '/' + packageTypeID).then(response => { return response.status });
const getSingleBatchLogs = ( batchID : number ) => axios.get<Log[]>(window.apiUrl +  '/log/getLogs/batch/' + batchID).then(response => { return response.data });

//const resp = await this.$http.get(`changeSendingMethodForOrder/${this.showSelectPackageTypeOrder.id}/${packageType.value}`)

function* fetchBatchByID( action : FetchSingleBatchData) {
    try {
        yield put(setSingleBatchWorking(true));

        const batch : Batch = yield call(getBatchByIDRequest, action.payload);
        let batchData : SingleBatchData = {
            batchID : action.payload,
            batchData : batch
        }

        const singleBatchJobID : number | undefined = yield select(getSingleBatchLabelJobID);
        if ( singleBatchJobID ) {
            if ( singleBatchJobID === action.payload ) {
                if ( !batch.is_generating_labels ) {
                    stopBatchLabelWatchJob();
                }
            }
        }

        yield put(setSingleBatchData(batchData));
        yield put(setSingleBatchWorking(false));

    }catch(e : any) {
        yield put(setSingleBatchWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batch by ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* fetchBatchPacklist( action : FetchSingleBatchPackList ) {
    try {
        yield put(setSingleBatchPacklist(undefined));

        const packlist : PackListItem[] = yield call(getPackListForBatch, action.payload);
        let packlistData : SingleBatchPacklist = {
            batchID : action.payload,
            packlistData : packlist
        }

        yield put(setSingleBatchPacklist(packlistData));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batch packlist: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* fetchBatchPicklist( action : FetchSingleBatchPickList ) {
    try {
        yield put(setSingleBatchPicklist(undefined));

        const picklist : PickList = yield call(getPickListForBatch, action.payload);

        let picklistData : SingleBatchPicklist = {
            batchID : action.payload,
            picklistData : Object.values(picklist)
        }

        yield put(setSingleBatchPicklist(picklistData));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batch picklist: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* generateMyParcelLabels( action : GenerateMyParcelLabels ) {
    try {
        yield put(setSingleBatchLabelData(undefined));
        let result : SingleBatchLabelData = yield call(generateMyParcelLabelsRequest, action.payload);
        result = {
            ...result,
            batchID : action.payload
        }

        yield put(setSingleBatchLabelData(result));
        yield put(fetchSingleBatchData(action.payload));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while generating MyParcel labels: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* maybeStartLabelWatchJob( action : SetSingleBatchData ) {
    try {
        const currentBatchData : SingleBatchData = yield select(getSingleBatchData);
        const currentRunningJob : number | undefined = yield select(getSingleBatchLabelJobID);

        if ( action.payload ) {
            if ( action.payload.batchData.is_generating_labels ) {
                // -- Start watchjob
                console.log(currentBatchData);
                console.log(currentRunningJob);
                if ( currentBatchData.batchID !== currentRunningJob ) {
                    yield put({
                        type : STOP_BATCH_LABEL_WATCH_JOB
                    })
                    yield put(setBatchLabelJobRunning(action.payload.batchID));
                    yield put(startBatchLabelWatchJob());
                }

            } else {
                // -- End watchjob
                yield put({
                    type : STOP_BATCH_LABEL_WATCH_JOB
                })
            }
        } else {
            yield put({
                type : STOP_BATCH_LABEL_WATCH_JOB
            })
        }

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while generating checking for label watchjob: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* startStopStatusUpdates() {
    while (true) {
        yield take(START_BATCH_LABEL_WATCH_JOB)
    	const bgSyncTask : Task = yield fork(backgroundSyncBatches)

    	yield take(STOP_BATCH_LABEL_WATCH_JOB)
    	yield cancel(bgSyncTask)
    }
}

function * backgroundSyncBatches() {
	while (true) {
        const singleBatchJobID : number | undefined = yield select(getSingleBatchLabelJobID);
        const currentBatchData : SingleBatchData = yield select(getSingleBatchData);
        if ( singleBatchJobID ) {
            if ( singleBatchJobID === currentBatchData.batchID ) {

                // -- This is where it all goes south
                let result : SingleBatchLabelData = yield call(getBatchesRetrievedRequest, singleBatchJobID);
                result = {
                    ...result,
                    batchID : singleBatchJobID
                }
                yield put(setSingleBatchLabelData(result));

            } else {
                yield put(stopBatchLabelWatchJob());
            }
        }
        yield delay(3000);
	}
}

function * stopWatchJob() {
    try {
        yield put(setBatchLabelJobRunning(undefined));
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while stopping label watchjob: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * checkLabelsComplete( action : SetSingleBatchLabelData) {
    try {
        if ( action.payload?.amountDone === action.payload?.amountTotal ) {
            const currentBatchData : SingleBatchData = yield select(getSingleBatchData);
            if ( currentBatchData.batchID === action.payload?.batchID ) {
                yield put(fetchSingleBatchData(action.payload.batchID));
            }
        }
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while checking labels completion: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * repairMyParcelLabels( action: RepairMyParcelLabels ) {


    try {
        const currentBatchData : SingleBatchData = yield select(getSingleBatchData);

        if ( currentBatchData ) {
            const batchLabelsWithError =  !(currentBatchData && currentBatchData.batchData.batch_items) ? [] : currentBatchData.batchData.batch_items.filter(item => parseInt(item.label_error) === 1);
            const labelData : SingleBatchLabelData = {
                amountDone : currentBatchData.batchData.batch_items.length - batchLabelsWithError.length,
                amountTotal : currentBatchData.batchData.batch_items.length,
                batchID : action.payload
            }

            yield put(setSingleBatchLabelData(labelData));
            yield call(repairPostNlLabelsRequest, action.payload);
            yield put(stopBatchLabelWatchJob());
            yield put(setBatchLabelJobRunning(action.payload));
            yield put(startBatchLabelWatchJob());
        }
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while reparing labels: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * updateBatchPicked( action: UpdateBatchPicked ) {
    try {
        yield call(updateBatchPickedRequest, action.payload);
        yield put(fetchSingleBatchData(action.payload));
        yield call(queueMailsRequest, action.payload);
    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while updating batch picked status: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * updateShippingMethodForOrder( action: UpdateShippingMethodForOrder ) {
    try {
        //yield put(setSingleBatchWorking(true));
        let singleBatchData: SingleBatchData = yield select(getSingleBatchData);

        singleBatchData.batchData.batch_items.forEach((item: BatchItem) => {

            if ( parseInt(item.order_id.toString()) === action.payload.orderID ) {
                item.woocommerce_order.shipping_type = action.payload.packageTypeID;
                console.log(item);
            }
        })

        yield put(setSingleBatchData(singleBatchData));

        const status : number = yield call(updateShippingMethodForOrderRequest, action.payload.orderID, action.payload.packageTypeID);

        if ( status !== 200 ) {
            yield put(
                setMessage({
                    notification : {
                        message : 'Something went wrong while updating shipping method',
                        type : 'error'
                    }
                })
            );
            yield put(fetchSingleBatchData(action.payload.batchID));
        }

    }catch(e : any) {
        yield put(fetchSingleBatchData(action.payload.batchID));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while updating batch shipping method: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function * fetchBatchLogs( action: FetchSingleBatchLogs ) {
    try {

        let singleBatchLogs: Log[] = yield call(getSingleBatchLogs, action.payload);

        let logs : SingleBatchLogs = {
            batchID : action.payload,
            logs : singleBatchLogs
        }

        yield put(setSingleBatchLogs(logs));

    }catch(e : any) {
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching batch logs: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* batchSaga() {
  yield all([
      takeLatest(FETCH_SINGLE_BATCH_DATA, fetchBatchByID),
      takeLatest(FETCH_SINGLE_BATCH_PACKLIST, fetchBatchPacklist),
      takeLatest(FETCH_SINGLE_BATCH_PICKLIST, fetchBatchPicklist),
      takeLatest(GENERATE_MYPARCEL_LABELS, generateMyParcelLabels),
      takeLatest(SET_SINGLE_BATCH_DATA, maybeStartLabelWatchJob),
      takeLatest(STOP_BATCH_LABEL_WATCH_JOB, stopWatchJob),
      takeLatest(SET_SINGLE_BATCH_LABEL_DATA, checkLabelsComplete),
      takeLatest(REPAIR_MYPARCEL_LABELS, repairMyParcelLabels),
      takeLatest(UPDATE_BATCH_PICKED, updateBatchPicked),
      takeEvery(UPDATE_SHIPPING_METHOD_FOR_ORDER, updateShippingMethodForOrder),
      takeLatest(FETCH_SINGLE_BATCH_LOGS, fetchBatchLogs),
      fork(startStopStatusUpdates)
  ]);
}

export default batchSaga;
