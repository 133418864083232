export const GET_USER_ROLES_REQUEST = "GET_USER_ROLES_REQUEST";
export const SET_USER_ROLES = "SET_USER_ROLES";

export const GET_USER_PERMISSIONS_REQUEST = "GET_USER_PERMISSIONS_REQUEST";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

export const CHECK_VALID_TOKEN = "CHECK_VALID_TOKEN";
export const AUTHENTICATE = "AUTHENTICATE";
export const SET_TOKEN = "SET_TOKEN";
export const GET_TOKEN_FROM_LOCALSTORAGE = "GET_TOKEN_FROM_LOCALSTORAGE";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const SET_ALL_USERS = "SET_ALL_USERS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";

export const SET_USER = "SET_USER";

export const DELETE_USER = "DELETE_USER";

export const LOGOUT = "LOGOUT";
export const LOGOUT_AND_LOGIN_AS_USER = "LOGOUT_AND_LOGIN_AS_USER";

export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD = "RESET_PASSWORD";
