import {
    GET_USER_ROLES_REQUEST,
    SET_USER_ROLES,
    GET_USER_PERMISSIONS_REQUEST,
    SET_USER_PERMISSIONS,
    CHECK_VALID_TOKEN,
    AUTHENTICATE,
    SET_TOKEN,
    GET_TOKEN_FROM_LOCALSTORAGE,
    GET_ALL_USERS_REQUEST,
    SET_ALL_USERS,
    UPDATE_USER_REQUEST,
    CREATE_USER_REQUEST,
    SET_USER,
    DELETE_USER,
    LOGOUT,
    LOGOUT_AND_LOGIN_AS_USER,
    REQUEST_PASSWORD_RESET,
    RESET_PASSWORD
} from "./actionTypes";


import {
    GetUserRolesRequest,
    GetAllUsersRequest,
    GetUserPermissionsRequest,
    SetUserRolesPayload,
    SetUserRoles,
    SetUserPermissionsPayload,
    SetUserPermissions,
    CheckValidToken,
    Authenticate,
    SetToken,
    AuthenticationPayload,
    GetTokenFromLocalStorage,
    SetAllUsers,
    UpdateUserRequest,
    CreateUserRequest,
    SetUser,
    DeleteUser,
    Logout,
    LogoutAndLoginAsUser,
    RequestPasswordReset,
    ResetPassword
} from "./types";

export const fetchUserRolesRequest = (): GetUserRolesRequest => ({
    type: GET_USER_ROLES_REQUEST,
});

export const logout = (): Logout => ({
    type: LOGOUT,
});

export const logoutAndLoginAsUser = (payload : string): LogoutAndLoginAsUser => ({
    type: LOGOUT_AND_LOGIN_AS_USER,
    payload : payload
});

export const resetPassword = (payload : { password : string, hash : string }): ResetPassword => ({
    type: RESET_PASSWORD,
    payload : payload
});

export const requestPasswordReset = (payload : string): RequestPasswordReset => ({
    type: REQUEST_PASSWORD_RESET,
    payload : payload
});

export const fetchAllUsers = (): GetAllUsersRequest => ({
    type: GET_ALL_USERS_REQUEST,
});

export const setAllUsers = ( payload : User[]): SetAllUsers => ({
    type: SET_ALL_USERS,
    payload : payload
});

export const setUser = ( payload : User | undefined): SetUser => ({
    type: SET_USER,
    payload : payload
});

export const setUserRoles = ( payload: SetUserRolesPayload ): SetUserRoles => ({
    type: SET_USER_ROLES,
    payload,
});

export const fetchUserPermissionsRequest = (): GetUserPermissionsRequest => ({
    type: GET_USER_PERMISSIONS_REQUEST,
});

export const setUserPermissions = ( payload: SetUserPermissionsPayload ): SetUserPermissions => ({
    type: SET_USER_PERMISSIONS,
    payload,
});

export const checkValidToken = () : CheckValidToken => ({
    type : CHECK_VALID_TOKEN
})

export const authenticate = ( payload : AuthenticationPayload ) : Authenticate => ({
    type : AUTHENTICATE,
    payload : payload
})

export const deleteUser = ( payload : number ) : DeleteUser => ({
    type : DELETE_USER,
    payload : payload
})

export const setToken = ( payload : string | null) : SetToken => ({
    type : SET_TOKEN,
    payload : payload
})

export const updateUserRequest = ( payload : User) : UpdateUserRequest => ({
    type : UPDATE_USER_REQUEST,
    payload : payload
})

export const createUserRequest = ( payload : User) : CreateUserRequest => ({
    type : CREATE_USER_REQUEST,
    payload : payload
})

export const getTokenFromLocalStorage = () : GetTokenFromLocalStorage => ({
    type : GET_TOKEN_FROM_LOCALSTORAGE
})
