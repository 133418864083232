export const GET_INCOMING_GOODS_REQUEST = "GET_INCOMING_GOODS_REQUEST";
export const SET_INCOMING_GOODS = "SET_INCOMING_GOODS";
export const CREATE_INCOMING_GOODS_REQUEST = "CREATE_INCOMING_GOODS_REQUEST";
export const UPDATE_INCOMING_GOODS_REQUEST = "UPDATE_INCOMING_GOODS_REQUEST";
export const DELETE_INCOMING_GOODS_REQUEST = "DELETE_INCOMING_GOODS_REQUEST";
export const DELETE_INCOMING_GOODS_FILE_REQUEST = "DELETE_INCOMING_GOODS_FILE_REQUEST";
export const SET_INCOMING_GOODS_WORKING = "SET_INCOMING_GOODS_WORKING";

export const START_INCOMING_GOOD_UPDATE_JOB = "START_INCOMING_GOOD_UPDATE_JOB";
export const STOP_INCOMING_GOOD_UPDATE_JOB = "STOP_INCOMING_GOOD_UPDATE_JOB";
export const SET_INCOMING_GOOD_UPDATE_JOB_RUNNING =  "SET_INCOMING_GOOD_UPDATE_JOB_RUNNING";
export const SET_INCOMING_GOODS_UPDATING = "SET_INCOMING_GOODS_UPDATING";
