import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { setStock, setStockWorking, setMaxStockPage} from "./actions";
import { EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST, EXPORT_ALL_CATEGORIES_REQUEST, EXPORT_BY_CATEGORY_REQUEST, EXPORT_BY_CATEGORY_AND_DATE_REQUEST, FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST, UPDATE_PRODUCT_META_REQUEST, UPDATE_PRODUCT_PRICE_REQUEST, EXPORT_BUNDLE_BY_DATE_REQUEST, EXPORT_BY_DATE_REQUEST, EXPORT_BY_SUPPLIER_REQUEST} from "./actionTypes";
import { ExportStockMutationsByUserRequest, ExportAllCategoriesRequest, ExportByCategoryRequest, ExportByCategoryAndDateRequest, FetchStockByFilterAndPageRequest, ExportBundleByDateRequest, ExportByDateRequest, ExportBySupplierRequest, UpdateProductMetaRequest, UpdateProductPriceRequest } from "./types";
import { getStockProducts } from '../config/selectors';
import { setMessage } from '../message/actions';
import openBlob from '../../helper/openBlob';

const getProductsRequest = (filter : string, filterValue : string,  page : number) => axios.get<ProductResponse>(window.apiUrl + '/stock/showStock?'+filter +'=' +  filterValue + '&page='+page).then(response => { return response.data})
const updateProductMetaDataRequest = (productID : number, updateField : string, updateValue : string) => axios.post<Product>(window.apiUrl + '/products/updateProductMetadata', { id : productID, update_field : updateField, [updateField] : updateValue}).then(response => { return response.data})
const updateProductPriceRequest = (productID : number, updateValue : number) => axios.post<Product>(window.apiUrl + '/products/updateProductPrice', { id : productID,  regular_price : updateValue}).then(response => { return response.data})
const exportStockByDateRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportByDate', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})
const exportStockBundlesByDateRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportBundleByDate', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})

const exportStockBySupplierRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportBySupplier', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})
const exportStockByCategoryRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportByCategory', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})
const exportStockByCategoryAndDateRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportByCategoryAndDate', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})
const exportAllCategoriesRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportAllCategories', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})
const exportStockMutationsRequest = (payload : any) => axios.post<Blob>(window.apiUrl + '/stock/exportStockMutations', { ...payload }, { responseType : 'blob' }).then(response => { return response.data})

function* fetchStock(action : FetchStockByFilterAndPageRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));


        const response: StockResponse = yield call(getProductsRequest, action.payload.filter, action.payload.filterValue,  action.payload.page);

        if ( response.result ) {
            yield put(
                setStock({
                    products: response.result,
                })
            )
            yield put(setMaxStockPage(response.maxPages));
        }

        yield put(setStockWorking(false));

    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching products: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateProductMeta(action : UpdateProductMetaRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        let products : StockProduct[] = yield select(getStockProducts);

        const productIndex : number  = products.findIndex((item : StockProduct) => item.ID === action.payload.productID);

        if ( productIndex > -1) {
            const product : StockProduct = products[productIndex];
            let updateField = action.payload.metaField;
            if ( action.payload.metaField === 'cost_of_good' ) {
                product.cost_of_good = action.payload.value;
                products[productIndex] = product;
                updateField = '_posr_cost_of_good';
            }

            yield put(
                setStock({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product meta ('+ action.payload.metaField+') updated successfully',
                        type : 'success'
                    }
                })
            );

            let result : Product = yield call(updateProductMetaDataRequest, action.payload.productID, updateField, action.payload.value);

            if ( updateField === '_posr_cost_of_good') {
                if ( result.meta_data ) {
                    const productMetaIndex = result.meta_data.findIndex((item : ProductMeta ) => item.key === updateField);
                    if ( productMetaIndex > -1) {
                        product.cost_of_good = parseFloat(result.meta_data[productMetaIndex].value);
                        products[productIndex] = product;
                    }
                }
            }

        }

    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product meta: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* updateProductPrice(action : UpdateProductPriceRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {

        let products : StockProduct[] = yield select(getStockProducts);

        const productIndex : number  = products.findIndex((item : StockProduct) => item.ID === action.payload.productID);

        if ( productIndex > -1 ) {
            products[productIndex].price = action.payload.value;
            yield put(
                setStock({
                    products: products
                })
            )

            yield put(
                setMessage({
                    notification : {
                        message : 'Product price updated successfully',
                        type : 'success'
                    }
                })
            );

            const result : Product = yield call(updateProductPriceRequest, action.payload.productID, action.payload.value);
            products[productIndex].price = result.regular_price;

            yield put(
                setStock({
                    products: products
                })
            )
        }

    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while update product price: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* exportByDate(action : ExportByDateRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
console.log(action.payload);
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockByDateRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating date export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* exportBundleByDate(action : ExportBundleByDateRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockBundlesByDateRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating date export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* exportAllCategories(action : ExportAllCategoriesRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportAllCategoriesRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating all categories export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* exportBySupplier(action : ExportBySupplierRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockBySupplierRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating date export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* exportByCategory(action : ExportByCategoryRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockByCategoryRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating date export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* exportStockMutations(action : ExportStockMutationsByUserRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockMutationsRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating stock mutation export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* exportByCategoryAndDate(action : ExportByCategoryAndDateRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setStockWorking(true));
        const result : Blob = yield call(exportStockByCategoryAndDateRequest, action.payload);
        openBlob(result, action.payload.filename + '.xlsx');
        yield put(setStockWorking(false));
    }catch(e : any) {
        yield put(setStockWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while creating date export: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* productSaga() {
  yield all([
      takeLatest(FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST, fetchStock),
      takeLatest(EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST, exportStockMutations),
      takeLatest(UPDATE_PRODUCT_META_REQUEST, updateProductMeta),
      takeLatest(UPDATE_PRODUCT_PRICE_REQUEST, updateProductPrice),
      takeLatest(EXPORT_BY_DATE_REQUEST, exportByDate),
      takeLatest(EXPORT_BUNDLE_BY_DATE_REQUEST, exportBundleByDate),
      takeLatest(EXPORT_BY_SUPPLIER_REQUEST, exportBySupplier),
      takeLatest(EXPORT_BY_CATEGORY_REQUEST, exportByCategory),
      takeLatest(EXPORT_BY_CATEGORY_AND_DATE_REQUEST, exportByCategoryAndDate),
      takeLatest(EXPORT_ALL_CATEGORIES_REQUEST, exportAllCategories)
  ]);
}

export default productSaga;
