import {
    SET_SINGLE_BATCH_DATA,
    SET_SINGLE_BATCH_PICKLIST,
    SET_SINGLE_BATCH_PACKLIST,
    SET_BATCH_LABEL_JOB_RUNNING,
    SET_SINGLE_BATCH_LABEL_DATA,
    SET_SINGLE_BATCH_LOGS,
    SET_SINGLE_BATCH_WORKING
} from "./actionTypes";


import { SingleBatchActions, SingleBatchState } from "./types";

const initialState: SingleBatchState = {
    data : undefined,
    picklist : undefined,
    logs : undefined,
    packlist :  undefined,
    numberOfUpdates : 0,
    labelData : undefined,
    labelJobRunning : undefined,
    working: false
};

const reducer = (state = initialState, action: SingleBatchActions) => {
    switch (action.type) {
        case SET_SINGLE_BATCH_DATA:
            return {
                ...state,
                data : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_BATCH_WORKING:
            return {
                ...state,
                working : action.payload
            };
        case SET_SINGLE_BATCH_LOGS:
            return {
                ...state,
                logs : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_BATCH_LABEL_DATA:
            return {
                ...state,
                labelData : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            }
        case SET_SINGLE_BATCH_PICKLIST:
            return {
                ...state,
                picklist : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_SINGLE_BATCH_PACKLIST:
            return {
                ...state,
                packlist : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            };
        case SET_BATCH_LABEL_JOB_RUNNING:
            return {
                ...state,
                labelJobRunning : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1,
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
