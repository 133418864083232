import {
    SET_PRODUCTS_WORKING,
    SET_PRODUCTS,
    SET_MAX_PRODUCTS_PAGE,
} from "./actionTypes";


import { ProductActions, ProductState } from "./types";

const initialState: ProductState = {
    numberOfUpdates : 0,
    maxPages : 0,
    currentPage : 1,
    products: [],
    working : false,
};

const reducer = (state = initialState, action: ProductActions) => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                numberOfUpdates : state.numberOfUpdates + 1,
                products: action.payload.products,
            };
        case SET_MAX_PRODUCTS_PAGE:
            return {
                ...state,
                maxPages : action.payload
            }
        case SET_PRODUCTS_WORKING:
            return {
                ...state,
                working: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
