import {
    FETCH_SINGLE_BATCH_DATA,
    FETCH_SINGLE_BATCH_PACKLIST,
    FETCH_SINGLE_BATCH_PICKLIST,
    SET_SINGLE_BATCH_DATA,
    SET_SINGLE_BATCH_PACKLIST,
    SET_SINGLE_BATCH_PICKLIST,
    GENERATE_MYPARCEL_LABELS,
    SET_SINGLE_BATCH_LABEL_DATA,
    SET_BATCH_LABEL_JOB_RUNNING,
    START_BATCH_LABEL_WATCH_JOB,
    STOP_BATCH_LABEL_WATCH_JOB,
    REPAIR_MYPARCEL_LABELS,
    UPDATE_BATCH_PICKED,
    UPDATE_SHIPPING_METHOD_FOR_ORDER,
    FETCH_SINGLE_BATCH_LOGS,
    SET_SINGLE_BATCH_LOGS,
    SET_SINGLE_BATCH_WORKING
} from "./actionTypes";

import {
    FetchSingleBatchData,
    SetSingleBatchData,
    StartBatchLabelWatchJob,
    StopBatchLabelWatchJob,
    FetchSingleBatchPickList,
    FetchSingleBatchPackList,
    SetSingleBatchPacklist,
    SetSingleBatchPicklist,
    GenerateMyParcelLabels,
    SetBatchLabelJobRunning,
    RepairMyParcelLabels,
    UpdateBatchPicked,
    UpdateShippingMethodForOrderPayload,
    UpdateShippingMethodForOrder,
    FetchSingleBatchLogs,
    SetSingleBatchLogs,
    SetSingleBatchWorking
} from "./types";

export const startBatchLabelWatchJob = () : StartBatchLabelWatchJob => ({
    type : START_BATCH_LABEL_WATCH_JOB
})

export const stopBatchLabelWatchJob = () : StopBatchLabelWatchJob => ({
    type : STOP_BATCH_LABEL_WATCH_JOB
})

export const setBatchLabelJobRunning = ( payload: number | undefined ): SetBatchLabelJobRunning => ({
    type: SET_BATCH_LABEL_JOB_RUNNING,
    payload,
});

export const fetchSingleBatchData = (payload : number ) : FetchSingleBatchData => ({
    type : FETCH_SINGLE_BATCH_DATA,
    payload : payload
})

export const fetchSingleBatchLogs = (payload : number ) : FetchSingleBatchLogs => ({
    type : FETCH_SINGLE_BATCH_LOGS,
    payload : payload
})

export const setSingleBatchLogs = ( payload: SingleBatchLogs | undefined ): SetSingleBatchLogs => ({
    type: SET_SINGLE_BATCH_LOGS,
    payload,
});

export const setSingleBatchData = ( payload: SingleBatchData | undefined ): SetSingleBatchData => ({
    type: SET_SINGLE_BATCH_DATA,
    payload,
});

export const fetchSingleBatchPacklist = ( payload : number) : FetchSingleBatchPackList => ({
    type : FETCH_SINGLE_BATCH_PACKLIST,
    payload : payload
})

export const setSingleBatchPacklist = ( payload : SingleBatchPacklist | undefined) : SetSingleBatchPacklist => ({
    type : SET_SINGLE_BATCH_PACKLIST,
    payload : payload
})

export const fetchSingleBatchPicklist = ( payload : number) : FetchSingleBatchPickList => ({
    type : FETCH_SINGLE_BATCH_PICKLIST,
    payload : payload
})

export const setSingleBatchPicklist = ( payload : SingleBatchPicklist | undefined) : SetSingleBatchPicklist => ({
    type : SET_SINGLE_BATCH_PICKLIST,
    payload : payload
})

export const generateMyParcelLabels = ( payload : number) : GenerateMyParcelLabels => ({
    type : GENERATE_MYPARCEL_LABELS,
    payload : payload
})

export const repairMyParcelLabels = ( payload : number) : RepairMyParcelLabels => ({
    type : REPAIR_MYPARCEL_LABELS,
    payload : payload
})

export const updateBatchPicked = ( payload : number ) : UpdateBatchPicked => ({
    type : UPDATE_BATCH_PICKED,
    payload : payload
})

export const setSingleBatchLabelData = (payload : SingleBatchLabelData | undefined ) : any => ({
    type : SET_SINGLE_BATCH_LABEL_DATA,
    payload : payload
})

export const updateShippingMethodForOrder = ( payload : UpdateShippingMethodForOrderPayload ) : UpdateShippingMethodForOrder => ({
    type : UPDATE_SHIPPING_METHOD_FOR_ORDER,
    payload : payload
})

export const setSingleBatchWorking = ( payload : boolean ) : SetSingleBatchWorking => ({
    type : SET_SINGLE_BATCH_WORKING,
    payload : payload
})
