import {
    GET_INCOMING_GOODS_REQUEST,
    SET_INCOMING_GOODS,
    CREATE_INCOMING_GOODS_REQUEST,
    UPDATE_INCOMING_GOODS_REQUEST,
    DELETE_INCOMING_GOODS_REQUEST,
    DELETE_INCOMING_GOODS_FILE_REQUEST,
    SET_INCOMING_GOODS_WORKING,
    SET_INCOMING_GOODS_UPDATING,
    START_INCOMING_GOOD_UPDATE_JOB,
    STOP_INCOMING_GOOD_UPDATE_JOB,
    SET_INCOMING_GOOD_UPDATE_JOB_RUNNING
} from "./actionTypes";

import {
    GetIncomingGoodsRequest,
    SetIncomingGoods,
    CreateIncomingGoodsRequest,
    UpdateIncomingGoodsRequest,
    DeleteIncomingGoodsRequest,
    DeleteIncomingGoodsFileRequest,
    DeleteIncomingGoodsFilePayload,
    SetIncomingGoodsWorking,
    StopIncomingGoodUpdateJob,
    StartIncomingGoodUpdateJob,
    SetIncomingGoodsUpdating,
    SetIncomingGoodUpdateJobRunning,
} from "./types";

export const getIncomingGoodsRequest = (): GetIncomingGoodsRequest => ({
    type: GET_INCOMING_GOODS_REQUEST,
});

export const setIncomingGoodsWorking = ( payload: boolean ): SetIncomingGoodsWorking => ({
    type: SET_INCOMING_GOODS_WORKING,
    payload,
});

export const setIncomingGoodsUpdating = ( payload: boolean ): SetIncomingGoodsUpdating => ({
    type: SET_INCOMING_GOODS_UPDATING,
    payload,
});

export const setIncomingGoods = ( payload : IncomingGood[]): SetIncomingGoods => ({
    type: SET_INCOMING_GOODS,
    payload : payload
});


export const deleteIncomingGoodsRequest = ( payload : number ) : DeleteIncomingGoodsRequest => ({
    type : DELETE_INCOMING_GOODS_REQUEST,
    payload : payload
})

export const deleteIncomingGoodsFileRequest = ( payload : DeleteIncomingGoodsFilePayload ) : DeleteIncomingGoodsFileRequest => ({
    type : DELETE_INCOMING_GOODS_FILE_REQUEST,
    payload : payload
})

export const updateIncomingGoodsRequest = ( payload : IncomingGood) : UpdateIncomingGoodsRequest => ({
    type : UPDATE_INCOMING_GOODS_REQUEST,
    payload : payload
})

export const createIncomingGoodsRequest = ( payload : IncomingGood) : CreateIncomingGoodsRequest => ({
    type : CREATE_INCOMING_GOODS_REQUEST,
    payload : payload
})


export const startIncomingGoodUpdateJob = () : StartIncomingGoodUpdateJob => ({
    type : START_INCOMING_GOOD_UPDATE_JOB
})

export const stopIncomingGoodUpdateJob = () : StopIncomingGoodUpdateJob => ({
    type : STOP_INCOMING_GOOD_UPDATE_JOB
})

export const setIncomingGoodUpdateJobRunning = ( payload: number | undefined ): SetIncomingGoodUpdateJobRunning => ({
    type: SET_INCOMING_GOOD_UPDATE_JOB_RUNNING,
    payload,
});
