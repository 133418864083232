import {
    SET_USER_ROLES,
    SET_USER_PERMISSIONS,
    SET_TOKEN,
    SET_ALL_USERS,
    SET_USER
} from "./actionTypes";

import { UserActions, UserState } from "./types";

const initialState: UserState = {
    roles : [],
    permissions : [],
    token : null,
    users : [],
    user : undefined
};

const reducer = (state = initialState, action: UserActions) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token : action.payload
            }
        case SET_USER:
            return {
                ...state,
                user : action.payload
            }
        case SET_ALL_USERS:
            return {
                ...state,
                users : action.payload
            }
        case SET_USER_ROLES:
            return {
                ...state,
                roles: action.payload.roles,
             };
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload.permissions,
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
