import {
    FETCH_SUPPLIERS_REQUEST,
    SET_SUPPLIERS,
    FETCH_CATEGORIES_REQUEST,
    SET_CATEGORIES,
} from "./actionTypes";

import {
    FetchSuppliersRequest,
    SetSuppliers,
    FetchCategoriesRequest,
    SetCategories
} from "./types";


export const fetchSuppliersRequest = (): FetchSuppliersRequest => ({
    type: FETCH_SUPPLIERS_REQUEST
});

export const setSuppliers = ( payload: Supplier[] ): SetSuppliers => ({
    type: SET_SUPPLIERS,
    payload,
});

export const fetchCategoriesRequest = (): FetchCategoriesRequest => ({
    type: FETCH_CATEGORIES_REQUEST
});

export const setCategories = ( payload: Category[] ): SetCategories => ({
    type: SET_CATEGORIES,
    payload,
});
