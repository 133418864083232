import {
    SET_SUPPLIERS,
    SET_CATEGORIES
} from "./actionTypes";


import { AttributeActions, AttributeState } from "./types";

const initialState: AttributeState = {
    suppliers : [],
    categories : []
};

const reducer = (state = initialState, action: AttributeActions) => {
    switch (action.type) {
        case SET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload,
            };
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
