export const FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST = "FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST";
export const SET_STOCK = "SET_STOCK";
export const SET_STOCK_WORKING = "SET_STOCK_WORKING";
export const SET_MAX_STOCK_PAGE = "SET_MAX_STOCK_PAGE";
export const EXPORT_BUNDLE_BY_DATE_REQUEST = "EXPORT_BUNDLE_BY_DATE_REQUEST";
export const UPDATE_PRODUCT_META_REQUEST = "UPDATE_PRODUCT_META_REQUEST";
export const UPDATE_PRODUCT_PRICE_REQUEST = "UPDATE_PRODUCT_PRICE_REQUEST";
export const EXPORT_BY_DATE_REQUEST = "EXPORT_BY_DATE_REQUEST";
export const EXPORT_BY_SUPPLIER_REQUEST = "EXPORT_BY_SUPPLIER_REQUEST";
export const EXPORT_BY_CATEGORY_REQUEST = "EXPORT_BY_CATEGORY_REQUEST";
export const EXPORT_BY_CATEGORY_AND_DATE_REQUEST = "EXPORT_BY_CATEGORY_AND_DATE_REQUEST";
export const EXPORT_ALL_CATEGORIES_REQUEST = "EXPORT_ALL_CATEGORIES_REQUEST";
export const EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST = "EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST";
