import {
    GET_OUTGOING_GOODS_REQUEST,
    SET_OUTGOING_GOODS,
    CREATE_OUTGOING_GOODS_REQUEST,
    UPDATE_OUTGOING_GOODS_REQUEST,
    DELETE_OUTGOING_GOODS_REQUEST,
    SET_OUTGOING_GOODS_WORKING
} from "./actionTypes";

import {
    GetOutgoingGoodsRequest,
    SetOutgoingGoods,
    CreateOutgoingGoodsRequest,
    UpdateOutgoingGoodsRequest,
    DeleteOutgoingGoodsRequest,
    SetOutgoingGoodsWorking
} from "./types";

export const getOutgoingGoodsRequest = (): GetOutgoingGoodsRequest => ({
    type: GET_OUTGOING_GOODS_REQUEST,
});

export const setOutgoingGoodsWorking = ( payload: boolean ): SetOutgoingGoodsWorking => ({
    type: SET_OUTGOING_GOODS_WORKING,
    payload,
});

export const setOutgoingGoods = ( payload : OutgoingGood[]): SetOutgoingGoods => ({
    type: SET_OUTGOING_GOODS,
    payload : payload
});


export const deleteOutgoingGoodsRequest = ( payload : number ) : DeleteOutgoingGoodsRequest => ({
    type : DELETE_OUTGOING_GOODS_REQUEST,
    payload : payload
})


export const updateOutgoingGoodsRequest = ( payload : OutgoingGood) : UpdateOutgoingGoodsRequest => ({
    type : UPDATE_OUTGOING_GOODS_REQUEST,
    payload : payload
})

export const createOutgoingGoodsRequest = ( payload : OutgoingGood) : CreateOutgoingGoodsRequest => ({
    type : CREATE_OUTGOING_GOODS_REQUEST,
    payload : payload
})
