import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { setOrders, setOrdersWorking, setMaxOrdersPage, fetchOrdersRequest } from "./actions";
import { FETCH_ORDER_WITH_ID_REQUEST, FETCH_ORDERS_REQUEST, SET_FETCH_BY_ORDER_STATUS, FETCH_ORDERS_BY_ZIP_AND_NAME_REQUEST } from "./actionTypes";
import {  FetchOrdersRequest, FetchOrderWithIdRequest, FetchOrdersByZipAndNameRequest} from "./types";
import { getFetchByOrderStatus } from '../config/selectors';
import { setMessage } from '../message/actions';

const getOrdersRequest = (page : number, status : string) => axios.post<OrderResponse>(window.apiUrl + '/getOrdersByStatus',  {status: status, page : page, per_page : 50}).then(response => { return response.data})
const getOrderByNumberRequest = (orderID : number) => axios.post<OrderByNumberResponse>(window.apiUrl + '/getOrderByNumber/'+orderID).then(response => { return response.data})
const getOrdersByZipAndNameRequest = (last_name : string, zip : string) => axios.post<OrderResponse>(window.apiUrl + '/getOrdersByZipAndName', { zip : zip, last_name : last_name}).then(response => { return response.data})

function* fetchOrders(action : FetchOrdersRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    try {
        yield put(setOrdersWorking(true));
        const fetchByOrderStatus : string = yield select(getFetchByOrderStatus);
        const response: OrderResponse = yield call(getOrdersRequest, action.payload, fetchByOrderStatus);

        if ( response.orders ) {
            yield put(
                setOrders({
                    orders: response.orders,
                })
            )
            yield put(setMaxOrdersPage(response.pageInfo.lastPage));
        }

        yield put(setOrdersWorking(false));

    }catch(e : any) {
        yield put(setOrdersWorking(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching orders: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* triggerFetchOrders(action : FetchOrdersRequest) /*: Generator<StrictEffect, void, Customer[]>*/ {
    yield put(
        fetchOrdersRequest(1)
    )
}

function* fetchOrderById(action : FetchOrderWithIdRequest)  {
    try {
        yield put(setOrdersWorking(true));
        const response: OrderByNumberResponse = yield call(getOrderByNumberRequest, action.payload);

        console.log(response);
        let orders : Order[] = [];
        let maxOrdersPage = 1;
        if ( response.order ) {
            orders.push(response.order);
            maxOrdersPage = response.pageInfo.lastPage;
        }

        yield put(
            setOrders({
                orders: orders,
            })
        )
        yield put(setMaxOrdersPage(maxOrdersPage));
        yield put(setOrdersWorking(false));

    }catch(e : any) {
        yield put(setOrdersWorking(false));
        yield put(
            setOrders({
                orders: [],
            })
        )
        yield put(setMaxOrdersPage(0));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching order with Order ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}

function* fetchOrderByZipAndName(action : FetchOrdersByZipAndNameRequest)  {
    try {
        yield put(setOrdersWorking(true));

        const response: OrderResponse = yield call(getOrdersByZipAndNameRequest, action.payload.name, action.payload.zipcode);
        let orders : Order[] = [];

        if ( response.orders ) {
            orders = response.orders;
        }

        yield put(
            setOrders({
                orders: orders,
            })
        )
        yield put(setMaxOrdersPage(0));
        yield put(setOrdersWorking(false));

    }catch(e : any) {
        yield put(setOrdersWorking(false));
        yield put(
            setOrders({
                orders: [],
            })
        )
        yield put(setMaxOrdersPage(0));
        yield put(
            setMessage({
                notification : {
                    message : 'Error while fetching order with Order ID: ' + e.message,
                    type : 'error'
                }
            })
        );
    }
}


function* orderSaga() {
  yield all([
      takeLatest(FETCH_ORDERS_REQUEST, fetchOrders),
      takeLatest(SET_FETCH_BY_ORDER_STATUS, triggerFetchOrders),
      takeLatest(FETCH_ORDER_WITH_ID_REQUEST, fetchOrderById),
      takeLatest(FETCH_ORDERS_BY_ZIP_AND_NAME_REQUEST, fetchOrderByZipAndName)
  ]);
}

export default orderSaga;
