export const FETCH_SINGLE_BATCH_DATA = "FETCH_SINGLE_BATCH_DATA";
export const SET_SINGLE_BATCH_DATA = "SET_SINGLE_BATCH_DATA";

export const FETCH_SINGLE_BATCH_PICKLIST = "FETCH_SINGLE_BATCH_PICKLIST";
export const SET_SINGLE_BATCH_PICKLIST = "SET_SINGLE_BATCH_PICKLIST";

export const FETCH_SINGLE_BATCH_PACKLIST = "FETCH_SINGLE_BATCH_PACKLIST";
export const SET_SINGLE_BATCH_PACKLIST = "SET_SINGLE_BATCH_PACKLIST";

export const GENERATE_MYPARCEL_LABELS = "GENERATE_MYPARCEL_LABELS";
export const REPAIR_MYPARCEL_LABELS = "REPAIR_MYPARCEL_LABELS";

export const SET_SINGLE_BATCH_LABEL_DATA = "SET_SINGLE_BATCH_LABEL_DATA";

export const UPDATE_BATCH_PICKED = "UPDATE_BATCH_PICKED";

export const START_BATCH_LABEL_WATCH_JOB = "START_BATCH_LABEL_WATCH_JOB";
export const STOP_BATCH_LABEL_WATCH_JOB = "STOP_BATCH_LABEL_WATCH_JOB";
export const SET_BATCH_LABEL_JOB_RUNNING =  "SET_BATCH_LABEL_JOB_RUNNING";

export const UPDATE_SHIPPING_METHOD_FOR_ORDER = "UPDATE_SHIPPING_METHOD_FOR_ORDER";

export const FETCH_SINGLE_BATCH_LOGS = "FETCH_SINGLE_BATCH_LOGS";
export const SET_SINGLE_BATCH_LOGS = "SET_SINGLE_BATCH_LOGS";

export const SET_SINGLE_BATCH_WORKING = "SET_SINGLE_BATCH_WORKING";
