import axios from '../../AxiosInstance/axiosWrapper';
import { all, call, put, takeLatest, fork, take, cancel, select, delay } from "redux-saga/effects";
import { setIncomingGoodsUpdating, setIncomingGoodsWorking, setIncomingGoods, getIncomingGoodsRequest, startIncomingGoodUpdateJob, stopIncomingGoodUpdateJob } from "./actions";
import { getIncomingGoodsData } from '../config/selectors';
import { push } from 'react-router-redux';
import { START_INCOMING_GOOD_UPDATE_JOB, STOP_INCOMING_GOOD_UPDATE_JOB, SET_INCOMING_GOOD_UPDATE_JOB_RUNNING, DELETE_INCOMING_GOODS_FILE_REQUEST, CREATE_INCOMING_GOODS_REQUEST, GET_INCOMING_GOODS_REQUEST, UPDATE_INCOMING_GOODS_REQUEST, DELETE_INCOMING_GOODS_REQUEST } from "./actionTypes";
import { DeleteIncomingGoodsFilePayload, DeleteIncomingGoodsFileRequest, CreateIncomingGoodsRequest, GetIncomingGoodsRequest, UpdateIncomingGoodsRequest, DeleteIncomingGoodsRequest } from './types';
import { setMessage } from '../message/actions';
import type { Task } from 'redux-saga';
interface FileUploadResponse {
    success : boolean;
    filename? : string;
}

interface FileDeleteResponse {
    success : boolean;
}

const deleteIncomingGoodsRequest = (ID : number) => axios.get<string[]>(window.apiUrl + '/incoming/delete/'+ID).then(response => { return response.data})
const getAllIncomingGoodsRequest = () => axios.get<IncomingGood[]>(window.apiUrl + '/incoming/all').then(response => { return response.data})
const createIncomingGoodsRequest = ( incomingGoodsData : IncomingGood) => axios.post<string[]>(window.apiUrl + '/incoming/create', { ...incomingGoodsData}).then(response => { return response.data})
const updateIncomingGoodsRequest = ( incomingGoodsData : IncomingGood, id : number) => axios.post<string[]>(window.apiUrl + '/incoming/update/'+id, { ...incomingGoodsData}).then(response => { return response.data})
const uploadFileRequest = (formData : FormData) => axios.post<FileUploadResponse>(window.apiUrl + '/incoming/uploadFile', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => { return response.data})
const deleteFileRequest = (payload : DeleteIncomingGoodsFilePayload) => axios.post<FileDeleteResponse>(window.apiUrl + '/incoming/deleteFile', payload).then(response => { return response.data})


function* getIncomingGoods( action : GetIncomingGoodsRequest) {
    try {
        yield put(setIncomingGoodsWorking(true));
        let result : IncomingGood[] = yield call(getAllIncomingGoodsRequest);

        let startStatusUpdates = false;
        result.forEach((item : IncomingGood) => {
            if ( item.locked === '1' ) {
                startStatusUpdates = true;
            }
        })

        if ( startStatusUpdates ) {
            yield put(setIncomingGoodsUpdating(true));
            yield put(startIncomingGoodUpdateJob());
        } else {
            yield put(setIncomingGoodsUpdating(false));
            yield put(stopIncomingGoodUpdateJob());
        }

        yield put(setIncomingGoods(result));
        yield put(setIncomingGoodsWorking(false));
    }catch(e : any) {
        yield put(setIncomingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while fetching all incoming goods: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* updateIncomingGoods( action : UpdateIncomingGoodsRequest) {
    try {
        yield put(setIncomingGoodsWorking(true));
        if ( action.payload.id ) {

            const formData = new FormData();
            if ( action.payload.pdf_file_upload?.length) {
                for ( let i = 0; i <= action.payload.pdf_file_upload.length; i++ ) {
                    if ( action.payload.pdf_file_upload[i] ) {
                        const file : File = action.payload.pdf_file_upload[i];
                        formData.append('file', file);
                        const response : FileUploadResponse = yield call(uploadFileRequest,  formData);
                        if ( response.success ) {
                            action.payload.pdf_file = response.filename;
                        }
                    }
                }
            }


            yield call(updateIncomingGoodsRequest, action.payload, action.payload.id);
            yield put(setIncomingGoodsUpdating(true));
            yield put(getIncomingGoodsRequest());

            yield put(startIncomingGoodUpdateJob());
            yield put(
                setMessage({
                    notification : {
                        message : 'Incoming good updated successfully:',
                        type : 'success'
                    }
                })
            );
        }
        yield put(setIncomingGoodsWorking(false));
    }catch(e : any) {
         yield put(setIncomingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while updating incoming good: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* createIncomingGoods( action : CreateIncomingGoodsRequest) {
    yield put(setIncomingGoodsWorking(true));
    try {

        const formData = new FormData();
        if ( action.payload.pdf_file_upload?.length) {
            for ( let i = 0; i <= action.payload.pdf_file_upload.length; i++ ) {
                if ( action.payload.pdf_file_upload[i] ) {
                    const file : File = action.payload.pdf_file_upload[i];
                    formData.append('file', file);
                    const response : FileUploadResponse = yield call(uploadFileRequest,  formData);
                    if ( response.success ) {
                        action.payload.pdf_file = response.filename;
                    }
                }
            }
        }

        const result : {id : number } = yield call(createIncomingGoodsRequest, action.payload);
        yield put(getIncomingGoodsRequest());
        yield put(setIncomingGoodsUpdating(true));
        yield put(push('/incoming/'));
        yield put(startIncomingGoodUpdateJob());
        yield put(
            setMessage({
                notification : {
                    message : 'Incoming good created successfully:',
                    type : 'success'
                }
            })
        );
        yield put(setIncomingGoodsWorking(false));
    }catch(e : any) {
        yield put(setIncomingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while creating incoming good: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* deleteIncomingGoods( action : DeleteIncomingGoodsRequest) {
    try {
         yield put(setIncomingGoodsWorking(true));
        const result : User = yield call(deleteIncomingGoodsRequest, action.payload);
        yield put(getIncomingGoodsRequest());
    
        yield put(
            setMessage({
                notification : {
                    message : 'Item deleted successfully',
                    type : 'success'
                }
            })
        );
      
    }catch(e : any) {
        yield put(setIncomingGoodsWorking(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while deleting incoming good: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* deleteIncomingGoodsFile( action : DeleteIncomingGoodsFileRequest) {
    try {

        yield put(setIncomingGoodsWorking(true));
        const result : FileDeleteResponse = yield call(deleteFileRequest, action.payload);
        if ( result.success ) {
            yield put(getIncomingGoodsRequest());
            yield put(
                setMessage({
                    notification : {
                        message : 'File deleted successfully',
                        type : 'error'
                    }
                })
            );
        }
        yield put(setIncomingGoodsWorking(false));
    }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Error while deleting file: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}



function* startStopStatusUpdates() {
    while (true) {
        yield take(START_INCOMING_GOOD_UPDATE_JOB)
    	const bgSyncTask : Task = yield fork(backgroundSyncIncomingGoods)

    	yield take(STOP_INCOMING_GOOD_UPDATE_JOB)
    	yield cancel(bgSyncTask)
    }
}

function * backgroundSyncIncomingGoods() {
	while (true) {

        let result : IncomingGood[] = yield call(getAllIncomingGoodsRequest);
        yield put(setIncomingGoods(result));

        let stopStatusUpdates = true;
        result.forEach((item : IncomingGood) => {
            if ( item.locked === '1' ) {
                stopStatusUpdates = false;
            }
        })

        if ( stopStatusUpdates ) {
            yield put(getIncomingGoodsRequest());
            yield put(stopIncomingGoodUpdateJob())
            yield put(setIncomingGoodsUpdating(false));
            yield put(
                setMessage({
                    notification : {
                        message : 'Updated successfully',
                        type : 'error'
                    }
                })
            );

        }

        yield delay(3000);
	}
}

function* userSaga() {
  yield all([
      takeLatest(GET_INCOMING_GOODS_REQUEST, getIncomingGoods),
      takeLatest(CREATE_INCOMING_GOODS_REQUEST, createIncomingGoods),
      takeLatest(UPDATE_INCOMING_GOODS_REQUEST, updateIncomingGoods),
      takeLatest(DELETE_INCOMING_GOODS_REQUEST, deleteIncomingGoods),
      takeLatest(DELETE_INCOMING_GOODS_FILE_REQUEST, deleteIncomingGoodsFile),
      fork(startStopStatusUpdates)
  ]);
}

export default userSaga;
