import {
    SET_STOCK_WORKING,
    SET_STOCK,
    SET_MAX_STOCK_PAGE,
} from "./actionTypes";


import { StockActions, StockState } from "./types";

const initialState: StockState = {
    numberOfUpdates : 0,
    maxPages : 0,
    products: [],
    working : false,
};

const reducer = (state = initialState, action: StockActions) => {
    switch (action.type) {
        case SET_STOCK:
            return {
                ...state,
                numberOfUpdates : state.numberOfUpdates + 1,
                products: action.payload.products,
            };
        case SET_MAX_STOCK_PAGE:
            return {
                ...state,
                maxPages : action.payload
            }
        case SET_STOCK_WORKING:
            return {
                ...state,
                working: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
