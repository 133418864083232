import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
import { NavLink } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

const Login = ( props : any) => {
    const [ password, setPassword ] = useState<string>('');
    const [ success, setSuccess ] = useState<boolean>(false);


   const _resetPassword = (e : React.SyntheticEvent) : void => {
       e.preventDefault();

       props.actions.resetPassword({ password : password, hash : props.match.params.hash})
       setSuccess(true);
   }

    return (
        <React.Fragment>
            <form onSubmit={_resetPassword}>
                <input type="password" placeholder="Nieuw wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button>Wachtwoord resetten</button>

            
            </form>
        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    return {
        location : state.router.location
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( Login ));
