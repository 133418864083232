import {
    SET_INCOMING_GOODS,
    SET_INCOMING_GOODS_WORKING,
    SET_INCOMING_GOODS_UPDATING
} from "./actionTypes";

import { IncomingGoodActions, IncomingGoodState } from "./types";

const initialState: IncomingGoodState = {
    incomingGoods : [],
    numberOfUpdates : 0,
    working : false,
    updating : false
};

const reducer = (state = initialState, action: IncomingGoodActions) => {
    switch (action.type) {
        case SET_INCOMING_GOODS:
            return {
                ...state,
                incomingGoods : action.payload,
                numberOfUpdates : state.numberOfUpdates + 1
            }
        case SET_INCOMING_GOODS_WORKING:
            return {
                ...state,
                working : action.payload
            }
        case SET_INCOMING_GOODS_UPDATING:
            return {
                ...state,
                updating : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
