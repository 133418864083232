import {
    FETCH_SINGLE_PRODUCT_DATA,
    SET_SINGLE_PRODUCT_DATA,
    SET_SINGLE_PRODUCT_WORKING,
    FETCH_SINGLE_PRODUCT_LOGS,
    SET_SINGLE_PRODUCT_LOGS
} from "./actionTypes";

import {
    FetchSingleProductData,
    SetSingleProductData,
    SetSingleProductWorking,
    FetchSingleProductLogs,
    SetSingleProductLogs
} from "./types";


export const fetchSingleProductData = (payload : number ) : FetchSingleProductData => ({
    type : FETCH_SINGLE_PRODUCT_DATA,
    payload : payload
})

export const setSingleProductData = ( payload: SingleProductData | undefined ): SetSingleProductData => ({
    type: SET_SINGLE_PRODUCT_DATA,
    payload : payload,
});

export const setSingleProductWorking = ( payload : boolean ) : SetSingleProductWorking => ({
    type : SET_SINGLE_PRODUCT_WORKING,
    payload : payload
})

export const fetchSingleProductLogs = (payload : number ) : FetchSingleProductLogs => ({
    type : FETCH_SINGLE_PRODUCT_LOGS,
    payload : payload
})

export const setSingleProductLogs = ( payload: SingleProductLogs | undefined ): SetSingleProductLogs => ({
    type: SET_SINGLE_PRODUCT_LOGS,
    payload,
});
