export const FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST = "FETCH_PRODUCTS_BY_FILTER_AND_PAGE_REQUEST";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCTS_WORKING = "SET_PRODUCTS_WORKING";
export const SET_MAX_PRODUCTS_PAGE = "SET_MAX_PRODUCTS_PAGE";
export const UPDATE_PRODUCT_META_REQUEST = "UPDATE_PRODUCT_META_REQUEST";
export const UPDATE_PRODUCT_FIELD_REQUEST = "UPDATE_PRODUCT_FIELD_REQUEST";
export const UPDATE_PRODUCT_PRICE_REQUEST = "UPDATE_PRODUCT_PRICE_REQUEST";
export const UPDATE_PRODUCT_SIZES_REQUEST = "UPDATE_PRODUCT_SIZES_REQUEST";
export const UPDATE_PRODUCT_WEIGHT_REQUEST = "UPDATE_PRODUCT_WEIGHT_REQUEST";
export const SET_SHIPPING_CLASS_WORKING = "SET_SHIPPING_CLASS_WORKING";
export const UPDATE_PRODUCT_STOCK_REQUEST = "UPDATE_PRODUCT_STOCK_REQUEST";
export const GET_PRODUCTS_BY_STOCK_REQUEST = "GET_PRODUCTS_BY_STOCK_REQUEST";
