import {
    SET_BATCHES,
    SET_MAX_BATCHES_PAGE,
    SET_CURRENT_BATCHES_PAGE,
    SET_BATCHES_WORKING,
    SET_BATCH_SETTINGS
} from "./actionTypes";

import { BatchActions, BatchState } from "./types";

const initialState: BatchState = {
    numberOfUpdates : 0,
    maxPages : 0,
    currentPage : 1,
    batches: [],
    working : false,
    settings : {
        orders_per_batch : undefined
    }
};

const reducer = (state = initialState, action: BatchActions) => {
    switch (action.type) {
        case SET_BATCHES:
            return {
                ...state,
                numberOfUpdates : state.numberOfUpdates + 1,
                batches: action.payload.batches,
            };
        case SET_BATCH_SETTINGS:
            return {
                ...state,
                settings : action.payload
            }
        case SET_BATCHES_WORKING:
            return {
                ...state,
                working: action.payload
            };
        case SET_MAX_BATCHES_PAGE:
            return {
                ...state,
                maxPages : action.payload
            }
        case SET_CURRENT_BATCHES_PAGE:
            return {
                ...state,
                currentPage : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
