import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
//import logger from "redux-logger";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

const history = createBrowserHistory()

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store
const store = createStore(rootReducer(history), compose(applyMiddleware(sagaMiddleware,  routerMiddleware(history))));

// Run the saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const storeConfig = { store, history }

export default storeConfig;
