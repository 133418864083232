import React from 'react';
import ReactDOM from 'react-dom';
import AuthenticatedAppWrapper from './component/authenticatedAppWrapper';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import ReduxConfig  from "./redux/config/store";
import { ConnectedRouter } from 'connected-react-router'
import './assets/style/scss/style.scss';
import './@types/index.d.ts';
import 'react-table/react-table.css'

declare global {
    interface Window {
        apiUrl : string,
        storageURL : string,
        redirectUrl : string,
        token : string | undefined
    }
}

if ( window.location.host === 'localhost:3000') {
    //window.apiUrl = 'https://weekendwebshop-backend.test/api';
    //window.storageURL = 'https://weekendwebshop-backend.test/storage';

    window.apiUrl = 'https://backend.weekendwebshop.nl/api';
    window.storageURL = 'https://backend.weekendwebshop.nl/storage';
} else {
    window.apiUrl = 'https://backend.weekendwebshop.nl/api';
    window.storageURL = 'https://backend.weekendwebshop.nl/storage';
}

//window.apiUrl = 'https://backend-update.debo.nl/api';
//window.storageURL = 'https://backend-update.debo.nl/storage';


ReactDOM.render(
    <React.StrictMode>
        <Provider store={ReduxConfig.store}>
            <ConnectedRouter history={ReduxConfig.history}>
                <AuthenticatedAppWrapper />
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
