export const FETCH_BATCHES_REQUEST = "FETCH_BATCHES_REQUEST";
export const FETCH_BATCH_WITH_ORDER_ID_REQUEST = "FETCH_BATCH_WITH_ORDER_ID_REQUEST";
export const SET_BATCHES = "SET_BATCHES";
export const GENERATE_BATCH_REQUEST = "GENERATE_BATCH_REQUEST";
export const UPDATE_USER_FOR_BATCH_REQUEST = "UPDATE_USER_FOR_BATCH_REQUEST";
export const SET_CURRENT_BATCHES_PAGE = "SET_CURRENT_BATCHES_PAGE";
export const SET_MAX_BATCHES_PAGE = "SET_MAX_BATCHES_PAGE";
export const SET_BATCHES_WORKING = "SET_BATCHES_WORKING";

export const FETCH_BATCH_SETTINGS_REQUEST = "FETCH_BATCH_SETTINGS_REQUEST";
export const UPDATE_BATCH_SETTINGS_REQUEST = "UPDATE_BATCH_SETTINGS_REQUEST";
export const SET_BATCH_SETTINGS = "SET_BATCH_SETTINGS";
