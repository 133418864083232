import { fetchBatchSettingsRequest } from '../batch/actions';
import { fetchAllUsers, fetchUserRolesRequest } from '../user/actions';
import { fetchSuppliersRequest, fetchCategoriesRequest } from '../attribute/actions';
import {  AppDispatch } from './store';
const boot = (dispatch : AppDispatch) => {
	new Promise(() => {
		dispatch(fetchAllUsers());
		dispatch(fetchUserRolesRequest());
		dispatch(fetchBatchSettingsRequest());
		dispatch(fetchSuppliersRequest());
		dispatch(fetchCategoriesRequest());
		//dispatch(getIncomingGoodsRequest());
	});
}

export default boot;
