import ReduxConfig, { RootState }  from "../redux/config/store";

export const _userHasPermission = (permission : string) => {

    const state : RootState = ReduxConfig.store.getState();
    const userRoles = state.User.user?.roles;

    if ( userRoles ) {
        if ( userRoles.find(( item : Role) => item.name === 'admin' ) ) {
            return true;
        }
        if ( userRoles.find(( item : Role) => item.name === permission ) ) {
            return true;
        }
    }

    return false;
}
