import {
    FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST,
    SET_STOCK,
    SET_STOCK_WORKING,
    SET_MAX_STOCK_PAGE,
    EXPORT_BUNDLE_BY_DATE_REQUEST,
    UPDATE_PRODUCT_META_REQUEST,
    UPDATE_PRODUCT_PRICE_REQUEST,
    EXPORT_BY_DATE_REQUEST,
    EXPORT_BY_SUPPLIER_REQUEST,
    EXPORT_BY_CATEGORY_REQUEST,
    EXPORT_BY_CATEGORY_AND_DATE_REQUEST,
    EXPORT_ALL_CATEGORIES_REQUEST,
    EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST
} from "./actionTypes";

import {
    FetchStockByFilterAndPagePayload,
    FetchStockByFilterAndPageRequest,
    SetStockPayload,
    SetStock,
    SetStockWorking,
    SetMaxStockPage,
    UpdateProductMetaPayload,
    UpdateProductMetaRequest,
    UpdateProductPricePayload,
    UpdateProductPriceRequest,
    ExportByDatePayload,
    ExportByDateRequest,
    ExportBundleByDateRequest,
    ExportBySupplierPayload,
    ExportBySupplierRequest,
    ExportByCategoryPayload,
    ExportByCategoryRequest,
    ExportByCategoryAndDatePayload,
    ExportByCategoryAndDateRequest,
    ExportAllCategoriesPayload,
    ExportAllCategoriesRequest,
    ExportStockMutationsByUserRequest,
    ExportStockMutationsByUserPayload
} from "./types";

export const fetchStockByFilterAndPageRequest = (payload : FetchStockByFilterAndPagePayload): FetchStockByFilterAndPageRequest => ({
    type: FETCH_STOCK_BY_FILTER_AND_PAGE_REQUEST,
    payload : payload
});


export const setStock = ( payload: SetStockPayload ): SetStock => ({
    type: SET_STOCK,
    payload,
});

export const setStockWorking = ( payload: boolean ): SetStockWorking => ({
    type: SET_STOCK_WORKING,
    payload,
});

export const setMaxStockPage = ( payload : number ) : SetMaxStockPage => ({
    type : SET_MAX_STOCK_PAGE,
    payload : payload
})

export const updateProductMetaRequest = (payload : UpdateProductMetaPayload ) : UpdateProductMetaRequest => ({
    type : UPDATE_PRODUCT_META_REQUEST,
    payload : payload
})

export const updateProductPriceRequest = ( payload : UpdateProductPricePayload ) : UpdateProductPriceRequest => ({
    type : UPDATE_PRODUCT_PRICE_REQUEST,
    payload : payload
})

export const exportByDateRequest = ( payload : ExportByDatePayload ) : ExportByDateRequest => ({
    type : EXPORT_BY_DATE_REQUEST,
    payload: payload
})

export const exportBundleByDateRequest = ( payload : ExportByDatePayload ) : ExportBundleByDateRequest => ({
    type : EXPORT_BUNDLE_BY_DATE_REQUEST,
    payload: payload
})

export const exportAllCategoriesRequest = ( payload : ExportAllCategoriesPayload ) : ExportAllCategoriesRequest => ({
    type : EXPORT_ALL_CATEGORIES_REQUEST,
    payload: payload
})


export const exportBySupplierRequest = ( payload : ExportBySupplierPayload ) : ExportBySupplierRequest => ({
    type : EXPORT_BY_SUPPLIER_REQUEST,
    payload: payload
})

export const exportByCategoryRequest = ( payload : ExportByCategoryPayload ) : ExportByCategoryRequest => ({
    type : EXPORT_BY_CATEGORY_REQUEST,
    payload: payload
})

export const exportByCategoryAndDateRequest = ( payload : ExportByCategoryAndDatePayload ) : ExportByCategoryAndDateRequest => ({
    type : EXPORT_BY_CATEGORY_AND_DATE_REQUEST,
    payload: payload
})

export const exportStockMutationsByUserRequest = ( payload : ExportStockMutationsByUserPayload ) : ExportStockMutationsByUserRequest => ({
    type : EXPORT_STOCK_MUTATIONS_BY_USER_REQUEST,
    payload: payload
})
