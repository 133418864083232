import React, { useEffect } from 'react';
import App from '../app';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Login from '../login';
import { ToastContainer } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
export const Loading = () => {
    return <div className="auth-loader">Authentication in progress...</div>
}


const AuthenticatedAppWrapper = ( props : any) => {

    useEffect(() => {
        // -- Check authorization
        if ( props.Token ) {
            props.actions.checkValidToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.location, props.Token]);
    return (
        <React.Fragment>

            <ToastContainer />
            { props.Token && props.User ? <App /> : <Login /> }
        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    return {
        Token : state.User.token,
        User : state.User.user,
        location : state.router.location
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( AuthenticatedAppWrapper ));
