import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';

const FullPageLoader = ( props : any) => {
    return (
        <React.Fragment>
            { props.BatchesLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.OrdersLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.SingleBatchLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.SingleOrderLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.ProductsLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.SingleProductLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.StockLoading ? <div className="loader loader--fullheight"></div> : null }
            { props.IncomingGoodsLoading ? <div className="loader loader--fullheight"></div> : null }
        </React.Fragment>
    )
}

const mapStateToProps = ( state : RootState ) => {
    return {
        BatchesLoading : state.Batch.working,
        OrdersLoading : state.Order.working,
        SingleBatchLoading : state.SingleBatch.working,
        SingleOrderLoading : state.SingleOrder.working,
        ProductsLoading : state.Product.working,
        SingleProductLoading : state.SingleProduct.working,
        StockLoading : state.Stock.working,
        IncomingGoodsLoading : state.IncomingGoods.working,
        IncomingGoodsUpdating : state.IncomingGoods.updating
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( FullPageLoader ));
