import {
    FETCH_ORDERS_REQUEST,
    SET_ORDERS,
    SET_CURRENT_ORDERS_PAGE,
    SET_MAX_ORDERS_PAGE,
    SET_ORDERS_WORKING,
    FETCH_ORDER_WITH_ID_REQUEST,
    SET_FETCH_BY_ORDER_STATUS,
    FETCH_ORDERS_BY_ZIP_AND_NAME_REQUEST
} from "./actionTypes";

import {
    FetchOrdersRequest,
    SetOrders,
    SetOrdersPayload,
    SetCurrentOrdersPage,
    SetMaxOrdersPage,
    SetOrdersWorking,
    FetchOrderWithIdRequest,
    SetFetchByOrderStatus,
    FetchOrdersbyZipAndNamePayload,
    FetchOrdersByZipAndNameRequest
} from "./types";

export const fetchOrdersRequest = (payload : number): FetchOrdersRequest => ({
    type: FETCH_ORDERS_REQUEST,
    payload : payload
});

export const setFetchByOrderStatus = (payload : string): SetFetchByOrderStatus => ({
    type: SET_FETCH_BY_ORDER_STATUS,
    payload : payload
});

export const fetchOrderWithIdRequest = (payload : number) : FetchOrderWithIdRequest => ({
    type : FETCH_ORDER_WITH_ID_REQUEST,
    payload : payload
})

export const fetchOrdersByZipAndNameRequest = (payload : FetchOrdersbyZipAndNamePayload ) : FetchOrdersByZipAndNameRequest => ({
    type : FETCH_ORDERS_BY_ZIP_AND_NAME_REQUEST,
    payload : payload
})

export const setOrders = ( payload: SetOrdersPayload ): SetOrders => ({
    type: SET_ORDERS,
    payload,
});

export const setCurrentOrdersPage = ( payload : number ) : SetCurrentOrdersPage => ({
    type : SET_CURRENT_ORDERS_PAGE,
    payload : payload
})

export const setMaxOrdersPage = ( payload : number ) : SetMaxOrdersPage => ({
    type : SET_MAX_ORDERS_PAGE,
    payload : payload
})

export const setOrdersWorking = ( payload : boolean ) : SetOrdersWorking => ({
    type : SET_ORDERS_WORKING,
    payload : payload
})
